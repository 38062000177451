import {createContext,useState} from 'react'

export const ThemeContext = createContext();

const ThemeProvider =({children})=>{
    const [colors, setColors]=useState({primary:'#ffea01', secondary:'black'})

    const changeColors= (newColors)=>{
        setColors(newColors);
    }
    return (
        <ThemeContext.Provider value={{ colors, changeColors }}>
            {children}
        </ThemeContext.Provider>
    )
}

export default ThemeProvider