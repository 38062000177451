import React from 'react'
import {Box, Button, Container, Divider, Typography, Tooltip, Fab} from '@mui/material'
import ClientListContainer from '../components/ClientListContainer/ClientListContainer'


const Clients = () => {
  return (
    <Container maxWidth='100%'>
        <Box mt={2}>
          <ClientListContainer/>
        </Box>
    </Container>
  )
}

export default Clients