import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Modal, TextField, Typography } from '@mui/material'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../context/UserContext'
import Loading from '../Loading/Loading';
import BoxModal from '../BoxModal/BoxModal'
import { ThemeContext } from '../../context/ThemeContext';
import ClientList from '../ClientList/ClientList'
import ClientForm from '../Form/ClientForm'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const initialValue={
    company:'',
    firstName:'',
    lastName:'',
    email:'',
    phoneNumber:'',
    url:''
  }

const ClientListContainer = () => {
    const {token} = useContext(UserContext)
    const {colors}=useContext(ThemeContext)
    const axiosPrivate= useAxiosPrivate()
    const [clients, setClients]=useState([])
    const [loading, setLoading]=useState(false)
    const [idClient, setIdClient]=useState()
    const [openDialog, setOpenDialog]=useState(false);
    const [open, setOpen]=useState(false);
    const [editMode, setEditMode] =useState(false);
    const [clientData, setClientData]=useState(initialValue);
    const [search, setSearch]= useState('')
    const [filteredClients, setFilteredClients]=useState([]);

    const getClients = async ()=>{
        const res = await axiosPrivate.get(`/clients`)
        if(res.data.success){
            setClients(res.data.data)
            setFilteredClients(res.data.data)
            setLoading(false)
            
        }
    }

    const getClient = async(id)=>{
        try {
            const res = await axiosPrivate.get(`/clients/${id}`)
            if(res){
                setClientData(res.data.data)
                setLoading(false)
                setOpen(true)
            }
        } catch (error) {
            console.log(error)
        }
    }
    
    const handleCloseOpenDialog=()=>{
        setOpenDialog(false)
      }
    
    const handleClickEdit= (id)=>{
        //setLoading(true)
        setEditMode(true)
        setIdClient(id)
        getClient(id)
      }
    const handleClickDelete=(id)=>{
        setIdClient(id)
        setOpenDialog(true)
      }
    const handleOpen=(state)=>{
        setEditMode(false)
        setOpen(true)};

    const handleClose=()=>{
        getClients();
        setClientData(initialValue)
        setOpen(false)
        };
    const handleDelete= async()=>{
        try {
        const result = await axiosPrivate.delete(`/clients/${idClient}`)
        handleCloseOpenDialog()
        getClients();
        } catch (error) {
        console.log("ERROR BORRADO", error)      
        }
    }
    const handleChangeSearch = (e)=>{
      setSearch(e.target.value)
      
  }
  const filterClient = async ()=>{
    let filteredRows = clients
    search ===''?
      filteredRows = clients
      :
       (filteredRows = await clients
             .filter((row)=>{
                 return row.company?.toLowerCase().indexOf(search.toLowerCase()) !== -1 || row.firstName.toLowerCase().indexOf(search.toLowerCase()) !== -1  || row.lastName.toLowerCase().indexOf(search.toLowerCase()) !== -1           
                })
       )
        setFilteredClients (filteredRows)
        setLoading(false)
}
        
    useEffect(()=>{
        setLoading(true)
        getClients()
        
    },[])
    useEffect(()=>{
      filterClient()
  },[search])


  return (
    <>
    {
        loading ? 
        <Box sx={{ display:'flex', textAlign:'center', justifyContent:'center', height:'50vh'  }}>
            <Loading />
        </Box>
        :
        <>
            <Grid container>
                <ClientList clients={filteredClients}  handleClickEdit={handleClickEdit} handleClickDelete={handleClickDelete} handleOpen={handleOpen} handleChangeSearch={handleChangeSearch}/>
            </Grid>
        </>
    }
    <Dialog
    open={openDialog}
    onClose={handleCloseOpenDialog}
  >
    <DialogTitle>
      {`Desea eliminar el cliente?`}
    </DialogTitle>
    <DialogContent>
      <DialogContentText>Si lo elimina no podrá verlo nuevamente</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseOpenDialog} autoFocus>NO</Button>
      <Button onClick={handleDelete}>SI</Button>
    </DialogActions>

  </Dialog>

  <Modal
      open={open}
      onClose={handleClose}
  >
      <BoxModal>
        <ClientForm actionClose={handleClose} clientData={clientData} editMode={editMode} />
      </BoxModal>
  </Modal>
            
</>  
    
  )
}

export default ClientListContainer