import { Alert, Autocomplete, Avatar, Box, Chip, Collapse, Fab, Grid, InputAdornment, MenuItem, Paper, Select,  Stack, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import ButtonZaz from '../ButtonZaz/ButtonZaz';
import { ThemeContext } from '../../context/ThemeContext';
import axios from 'axios';
import {Field, useFormik} from 'formik';
import * as yup from 'yup';
import { UserContext } from '../../context/UserContext';
import LogoBackground from '../LogoBackground/LogoBackground';
import CloseIcon from '@mui/icons-material/Close';
import { ConfigContext } from '../../context/ConfigContext';
import dayjs from 'dayjs';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const validationSchema = yup.object({
    client: yup
        .string('Ingrese Cliente')
        .required('Es necesario que ingrese el Cliente'),
    products: yup
        .array().min(1)
        .required('Es necesario que ingrese Productos'),
    totalPrice: yup
        .number('Ingrese Precio')
        .required('Es necesario que ingrese precio'),
    discount: yup
        .number('Ingrese descuento'),
    creationDate: yup
        .string('Ingrese fecha de creacion')
        .required('Es necesario que ingrese fecha de vencimiento'),    
    approvedDate: yup
        .string('Ingrese fecha de aprobacion')
        .required('Es necesario que ingrese fecha de aprobacion'),
    state: yup
        .string('Ingrese estado')
        .required('Es necesario que ingrese el estado'),
    
},{timestamps:true})

const states=[{id:1,value:"pending",label:"Pendiente"},{id:2,value:"ok",label:"Aprobado"},{id:3,value:"send",label:"Enviado"},{id:4, value:"rejected",label:"Rechazado"}]

const SaleForm = ({data,actionClick, actionClose, editMode, saleData, clients, products}) => {
    const {colors}=useContext(ThemeContext)
    const {cotizacion} = useContext(ConfigContext)
    const axiosPrivate= useAxiosPrivate();
    const [open, setOpen]=useState(false);
    const [message, setMessage]=useState("");
    const [selectedProducts, setSelectedProducts ]=useState([]);
    const [totalNational, setTotalNational]=useState(0);
    const [totalInternational, setTotalInternational] =useState(0);
    const [finalPrice, setFinalPrice]=useState(0);

    const handleChangeProducts = (e)=>{
        setSelectedProducts(e.target.value)
    }

      

    const formik = useFormik({
        initialValues: saleData,
        validationSchema: validationSchema,
        onSubmit: async (values)=>{
            //values.totalPrice = finalPrice
            values.products=selectedProducts
                if(!editMode){
                    const result = await axiosPrivate.post(`/sales/`,JSON.stringify(values,null,2))
                    .catch((err)=>{
                    const errorMessage="Intente nuevamente"
                    setOpen(true)
                    setMessage(errorMessage)
                    })
                    if(result.data.success){
                       actionClose()
                    } 
                }else{
                    const result = await axiosPrivate.put(`/sales/${values._id}`,JSON.stringify(values,null,2))
                    .catch((err)=>{
                        const errorMessage="Intente nuevamente"
                        setOpen(true)
                        setMessage(errorMessage)
                        })
                    if(result.data.message){
                       
                        actionClose()
                    } 
                }
 
        }
    })
    
    const changePrices = ()=>{

        if(selectedProducts.length>0){
            setTotalInternational(0)
            setTotalNational(0)
            let totalInt=0
            let totalNac=0
            selectedProducts.map((selectedProduct)=>{
            const productObject=products.find((product)=>product._id===selectedProduct)
                totalInt = totalInt + Number(productObject.priceInternational)
                totalNac = totalNac + Math.ceil((Number(productObject.priceNational)*cotizacion))
                setTotalInternational(totalInt)
                setTotalNational(totalNac)
            })
        }else{
            setTotalInternational(0)
            setTotalNational(0)
        }
    }
    const handleChangeDiscount =(e)=>{
        const totalDiscount= Number(formik.values.totalPrice) - e.target.value * Number(formik.values.totalPrice) / 100
        setFinalPrice(Math.ceil(totalDiscount))
        formik.values.discount=Number(e.target.value)

    }
    const handleChangeTotal = (e)=>{
        formik.values.totalPrice=e.target.value
        const totalDiscount= Number(e.target.value) - Number(formik.values.discount) * Number(e.target.value) / 100
        setFinalPrice(Math.ceil(totalDiscount))
    }
    const handleClick = async(e)=>{
        formik.values.products=selectedProducts
        //formik.values.totalPrice = finalPrice
        formik.submitForm()
                if(!editMode){
                    const result = await axiosPrivate.post(`/sales/`,JSON.stringify(formik.values,null,2))
                    .catch((err)=>{
                    const errorMessage="Intente nuevamente"
                    setOpen(true)
                    setMessage(errorMessage)
                    })
                    if(result.data.success){
                       actionClose()
                    } 
                }else{
                    const result = await axiosPrivate.put(`/sales/${formik.values._id}`,JSON.stringify(formik.values,null,2))
                    .catch((err)=>{
                        const errorMessage="Intente nuevamente"
                        setOpen(true)
                        setMessage(errorMessage)
                        })
                    if(result.data.message){
                        actionClose()
                    } 
                }
    }
    useEffect(()=>{
        changePrices()
        
    },[selectedProducts])

    useEffect(()=>{
        if(saleData?.products)
        {
            const arrSelectedProducts=[]
            //setSelectedProducts(saleData.products)
            saleData.products.map((product)=>{
                arrSelectedProducts.push(product._id)
            })
            setSelectedProducts(arrSelectedProducts)
          
        }
        saleData.totalPrice &&
        setFinalPrice(saleData.totalPrice - saleData.totalPrice*saleData.discount /100) 
    
    },[])

    

  return (
    <>
    <Box component='form' >
        <Fab size='small' sx={{ position:'absolute', top:0, right:0, m:2, p:2,backgroundColor: colors.primary, color: 'black', '&:hover':{backgroundColor:colors.secondary, color:colors.primary} }} onClick={()=>actionClose()}><CloseIcon/></Fab>
        <LogoBackground/>
    </Box>
        <Paper elevation={20} sx={{ justifyContent:'center', padding:2,   }}>
            <Grid align='center'>
                <Avatar sx={{bgcolor:colors.primary}}>
                    <Box sx={{  height:40 }} component='img' src='/assets/images/logo-sin-fondo.svg'></Box>
                </Avatar>
                <Typography align='center' variant='h5'py={2} >{editMode?"EDITAR PRESUPUESTO": "NUEVO PRESUPUESTO"}</Typography>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                <Box component={'form'} onSubmit={formik.handleSubmit}>
                    <Stack spacing={1} sx={{ mt:1 }}>
                        <Autocomplete
                            id="client"
                            name="client"
                            label="Cliente"
                            size="small"
                            freeSolo
                            options={clients}
                            defaultValue={formik.values.client}
                            getOptionLabel={option=>option?.company}
                            onChange={(e,value)=>{
                                formik.values.client=value._id
                            }}
                            renderInput = {params => (
                                <TextField
                                    label="Cliente"
                                    error={formik.touched.client && Boolean(formik.errors.client)}
                                    helperText={formik.touched.client && formik.errors.client}
                                    autoComplete="on"
                                    fullWidth
                                    
                                    required
                                    {...params}

                                />
                            )}
                        />
                        <TextField
                            id="products"
                            name="products"
                            label="Productos"
                            size="small"
                            value={formik.values.products}
                            onChange={formik.handleChange}
                            error={formik.touched.products && Boolean(formik.errors.products)}
                            helperText={formik.touched.products && formik.errors.products}
                            autoComplete="on"
                            fullWidth
                            required
                            select
                            SelectProps={{ 
                                multiple:true,
                                value: selectedProducts,
                                onChange:handleChangeProducts,
                                renderValue:(selected)=>(
                                    <Box sx={{display:'flex', flexWrap:'wrap', gap:0.5  }}>
                                        {selected?.map((value)=>{
                                            const productSelected = products.find((product)=> product._id===value)
                                           return <Chip size='small' key={productSelected._id} label={productSelected.title}/>
                                        }
                                        )}
                                    </Box>

                                )
                            }}
                        >
                            {
                                products?.map((product)=>(
                                    <MenuItem key={product._id} value={product._id}>
                                        {product.title}
                                    </MenuItem>
                                ))

                            }
                        </TextField>
                        <TextField
                            id="totalPrice"
                            name="totalPrice"
                            label="Total"
                            type='number'
                            size="small"
                            defaultValue={formik.values.totalPrice}
                            onChange={handleChangeTotal}
                            error={formik.touched.totalPrice && Boolean(formik.errors.totalPrice)}
                            helperText={(<Typography variant='caption'>Precio Sugerido Nacional: {totalNational} <br/>Precio Sugerido Internacional: {totalInternational}</Typography> )|| (formik.touched.totalPrice && formik.errors.totalPrice)}
                            autoComplete="on"
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                        />
                        <TextField
                            id="discount"
                            name="discount"
                            label="Descuento"
                            type='number'
                            size="small"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                endAdornment: <InputAdornment position="end">{`$ ${finalPrice}`}</InputAdornment>,
                              }}
                            defaultValue={formik.values.discount}
                            onChange={handleChangeDiscount}
                            error={formik.touched.discount && Boolean(formik.errors.discount)}
                            helperText={formik.touched.discount && formik.errors.discount}
                            autoComplete="on"
                            fullWidth
                        />

                        <TextField
                        id="creationDate"
                        name="creationDate"
                        label="Fecha de creacion"
                        type='date'
                        size="small"
                        value={dayjs(formik.values.creationDate).format('YYYY-MM-DD')}
                        onChange={formik.handleChange}
                        error={formik.touched.creationDate && Boolean(formik.errors.creationDate)}
                        helperText={formik.touched.creationDate && formik.errors.creationDate}
                        autoComplete="on"
                        fullWidth
                        required
                        InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                        id="approvedDate"
                        name="approvedDate"
                        label="Fecha de aprobado"
                        type='date'
                        size="small"
                        value={dayjs(formik.values.approvedDate).format('YYYY-MM-DD')}
                        onChange={formik.handleChange}
                        error={formik.touched.approvedDate && Boolean(formik.errors.approvedDate)}
                        helperText={formik.touched.approvedDate && formik.errors.approvedDate}
                        autoComplete="on"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                        id="state"
                        name="state"
                        label="Estado"
                        size="small"
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        error={formik.touched.state && Boolean(formik.errors.state)}
                        helperText={formik.touched.state && formik.errors.state}
                        autoComplete="on"
                        fullWidth
                        required
                        select
                        >
                            {
                                states.map((state)=>(
                                    <MenuItem key={state.id} value={state.value}>
                                        {state.label}
                                    </MenuItem>
                                ))

                            }
                        </TextField>
                        <ButtonZaz type="button" onClick={handleClick} >{editMode?"EDITAR":"NUEVO"} PRESUPUESTO</ButtonZaz>
                        
                        <Collapse in={open}>
                            <Alert
                            severity="error" variant="outlined"
                            sx={{ mb: 2 }}
                            onClose={()=>{
                                setOpen(false)
                            }}
                            >
                            {message}
                            </Alert>
                        </Collapse>
                    </Stack>
                </Box>
                </Grid>

            </Grid>
            
        </Paper>
    
    </>
  )
}

export default SaleForm