import { Route, Routes } from 'react-router-dom';
import './App.css';

import Clients from './pages/Clients';
import Dashboard from './pages/Dashboard';
import Balance from './pages/Balance';
import Services from './pages/Services';
import Sales from './pages/Sales';
import Users from './pages/Users';
import Login from './pages/Login';
import SidebarLayout from './components/SidebarLayout/SidebarLayout';

import Product from './pages/Product';
import RequireAuth from './components/RequireAuth/RequireAuth';
import Tasks from './pages/Tasks';

function App() {
  return (
    <Routes>
      <Route element={<SidebarLayout/>}>
        <Route element={<RequireAuth/>}>
          <Route path="/" element={<Dashboard/>}/>
          <Route path="/clients" element={<Clients/>}/>
          <Route path="/dashboard" element={<Dashboard/>}/>
          <Route path="/services" element={<Services/>}/>
          <Route path="/products" element={<Product/>}/>
          <Route path="/sales" element={<Sales/>}/>
          <Route path="/balance" element={<Balance/>}/>
          <Route path="/users" element={<Users/>}/>
          <Route path="/tasks" element={<Tasks/>}/>
        </Route>
      </Route>
      <Route path="/login" element={<Login/>}/>
    </Routes>
  );
}

export default App;
