import { Box, getStepLabelUtilityClass, Grid, Modal, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import DashboardCard from '../DashboardCard/DashboardCard'
import DashboardChart from '../DashboardChart/DashboardChart'
import DashboardTable from '../DashboardTable/DashboardTable'
import ContactPageIcon from '@mui/icons-material/ContactPage';
import WebIcon from '@mui/icons-material/Web';
import GroupIcon from '@mui/icons-material/Group';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BoxModal from '../../BoxModal/BoxModal'
import Form from '../../Form/FormOld'
import { ThemeContext } from '../../../context/ThemeContext'
import { UserContext } from '../../../context/UserContext'
import Loading from '../../Loading/Loading'
import useAuth from '../../../hooks/useAuth'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import TransactionForm from '../../Form/TransactionForm'
import ClientForm from '../../Form/ClientForm'
import ProductForm from '../../Form/ProductForm'
import SaleForm from '../../Form/SaleForm'
import ServiceForm from '../../Form/ServiceForm'


const chartData = {
  labels: [ 'Vencidos', 'Proximos','Activos'],
  datasets: [
    {
      label: '# Servicios',
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
      ],
      borderWidth: 1,
      
    },
  ],
};

const DashboardContanier = () => {
  const {colors}=useContext(ThemeContext)
  const [open, setOpen]=useState(false)
  const [formData, setFormData]=useState()
  const [info, setInfo]=useState()
  const [loading, setLoading]=useState(false)
  const [clients, setClients] = useState([])
  const [products,setProducts]= useState([])
  const [sales,setSales] = useState([])
  const [type, setType] = useState();
  const axiosPrivate=useAxiosPrivate();

  const getInfo = async () => {
    const res = await axiosPrivate.get(`/admin/dashboard`)
    if(res.data.success){
      setInfo(res.data.data)
    }
    setLoading(false)
  }


  const getClients=async()=>{
      const res = await axiosPrivate.get(`/clients`)
      setClients(res.data.data)
  }
  const getProducts = async()=>{
      const res = await axiosPrivate.get(`/products`)
      setProducts(res.data.data)
  }
  const getSales = async()=>{
    const res = await axiosPrivate.get(`/sales`)
    if(res.data.success){
      setSales(res.data.data)
    }
  }
  const handleOpen=()=>{
    setOpen(true)
  }
  const handleClose=()=>{
    setOpen(false)
  }
  
  const handleClick=(id)=>{
      setType(id)
      handleOpen()
  }


  const actions=[
    {icon:<ContactPageIcon/> , name:'Cliente',action: handleClick, id:'client', data: {
      id:'cliente',
      title:'NUEVO CLIENTE',
      fields:[
        {type:'text',label:'Nombre Cliente'},
        {type:'text',label:'Nombre Empresa'},
        {type:'phone', label:'Teléfono'},
        {type:'email', label:'Correo Electrónico'},
      ],
      button:'AGREGAR CLIENTE'
    }},
    {icon:<WebIcon/>, name:'Servicios',action: handleClick, id:'service', data: {
      id:'servicio',
      title:'NUEVO SERVICIO',
      fields:[
        {type:'text',label:'Tipo'},
        {type:'text',label:'Cliente'},
        {type:'date', label:'Fecha de Inicio'},
        {type:'text', label:'Proveedor'},
      ],
      button:'AGREGAR SERVICIO'
    } },
    {icon:<SummarizeIcon/>, name:'Precios',action: handleClick, id:'product',
    data:{
      id:'precio',
      title:'NUEVO PRECIO',
      fields:[
        {type:'text',label:'Titulo'},
        {type:'number',label:'Precio Nacional'},
        {type:'number', label:'Precio Internacional'},
      ],
      button:'AGREGAR PRECIO'
    }},
    {icon:<AttachMoneyIcon/>, name:'Presupuesto',action: handleClick, id:'sale',
    data:{
      id:'presupuesto',
      title:'NUEVO PRESUPUESTO',
      fields:[
        {type:'text',label:'Cliente'},
        {type:'text',label:'Productos'},
        {type:'date', label:'Fecha'},
        {type:'number',label:'Precio'},
      ],
      button:'AGREGAR PRESUPUESTO'
    }},
    {icon:<AccountBalanceIcon/> , name:'Transacción', action: handleClick, id:'transaction',
      data:{
      id:'transaccion',
      title:'NUEVA TRANSACCION',
      fields:[
        {type:'text',label:'Tipo'},
        {type:'number',label:'Cantidad'},
        {type:'text', label:'Descripcion'},
      ],
      button:'AGREGAR TRANSACCION'
    }},
    {icon:<GroupIcon/>, name:'Usuario',action: handleClick, id:'user',
      data:{
      id:'usuario',
      title:'NUEVO USUARIO',
      fields:[
        {type:'text',label:'Nombre'},
        {type:'text',label:'Usuario'},
        {type:'password', label:'Password'},
        {type:'password', label:'Confirmar Password'},
      ],
      button:'AGREGAR USUARIO'
    }}
  ]

  useEffect(()=>{
    setLoading(true)
    getClients()
    getProducts()
    getSales()
    getInfo();
  },[])

  
  return (
  <>
    {
      
      loading ? 
      <Box sx={{ display:'flex', textAlign:'center', justifyContent:'center', height:'50vh'  }}>
          <Loading />
      </Box>
      :
      <>

        {info && 
        <Grid container mt={5} spacing={2}>
        <Grid item md={2} sm={6} >
          <DashboardChart data={chartData} type={'doughnut'} dataset={info.serviceChart.data}/>
        </Grid>
          {
              info.cards.map((item,index)=>{
                  return <DashboardCard key={index} data={item}/>
              })
          }
        <Grid item md={6} sm={12}>
          <DashboardChart data={chartData} type={'bar'}  dataset={info.dataBar}/>
        </Grid>
        <Grid item md={6} sm={12}>
          <DashboardChart data={chartData} type={'line'} dataset={info.dataLine}/>
        </Grid>
        <Grid item md={12} sm={12}>
          <DashboardTable data={info.servicesExpiring} />
        </Grid>
          <SpeedDial
            ariaLabel="Agregar"
            icon={<SpeedDialIcon />}
            direction='right'
            sx={{'& .MuiFab-primary': { width: 40, height: 40, backgroundColor: colors.primary, color: colors.secondary  }, '& .MuiFab-primary:hover': { width: 40, height: 40, backgroundColor: colors.secondary, color: colors.primary  }, position:'absolute', ml:-4, mt:-0.5,  }}
          >
            {actions.map((action, index)=>{
              return(

                <SpeedDialAction
                  key={index}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  tooltipPlacement='bottom'
                  onClick={()=>handleClick(action.id)}
              
                />
              )
            }
              
            )}
          </SpeedDial>
          <Modal
            open={open}
            onClose={handleClose}
        >
            <BoxModal>
                {type==='client' && <ClientForm actionClose={handleClose} clientData={{  }} editMode={false} />}
                {type==='transaction' && <TransactionForm actionClose={handleClose} transactionData={{ }} editMode={false} sales={sales} />}
                {type==='product' && <ProductForm actionClose={handleClose} productData={{ } } editMode={false} />}
                {type==='sale' && <SaleForm actionClose={handleClose} saleData={{ }} editMode={false} clients={clients} products={products} />}
                {type==='service' && <ServiceForm actionClose={handleClose} serviceData={{  }} editMode={false} clients={clients} products={products} />}
            </BoxModal>
        </Modal>
        </Grid>
         
        
      }
      </>
    }
  </>
    
  )
}

export default DashboardContanier