import { Box, Container, Divider, Typography } from '@mui/material'
import React from 'react'
import TasksListContainer from '../components/TasksListContainer/TasksListContainer'

const Tasks = () => {
  
  return (
    <Container maxWidth='100%'>
        <Box mt={2} width={'100%'}>
          <TasksListContainer />
        </Box>
    </Container>
  )
}

export default Tasks