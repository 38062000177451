import { Card, CardActions, CardContent, CardHeader, Grid, Paper, Typography } from '@mui/material'
import React from 'react'

const DashboardCard = ({data}) => {
  return (
    <Grid item xs={12} sm={6} md={4} xl={2} >
        <Card elevation={5}>
            <CardHeader title={data.title} titleTypographyProps={{ variant:'body2', align:'left', fontWeight:'bold',p:1, backgroundColor:'black', color:'white' }}  sx={{p:0.5}} />
            <CardContent >
                <Typography variant='h6' sx={{ p:data.amount.length>4?1:0, fontSize:data.amount.length>4?20:30, fontWeight:'bold' }}>{data.amount}</Typography>
            </CardContent>
        </Card>
    </Grid>
    
  )
}

export default DashboardCard