import { Box, Fab, Grid, Tooltip, Typography, TextField, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Chip, ButtonGroup, IconButton } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../../context/ThemeContext'
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ProgressBar from '../ProgressBar/ProgressBar';

const TasksList = ({ tasks , handleOpen, handleChangeSearch, filterType, handleChangeFilterType, filterState, handleChangeFilterState, columns, statusLabels, handleClickEdit, handleClickDelete}) => {
  const {colors}=useContext(ThemeContext)
  const [page, setPage]= useState(0);
  const [rowsPerPage, setRowsPerPage]=useState(10);

  const handleChangePage =(e,newPage)=>{
    setPage(newPage);
  }
  const handleChangeRowsPerPage=(e)=>{
      setRowsPerPage(e.target.value);
      setPage(0);
  }

  return (
    <Grid container width={'100%'}>
      <Grid item md={12}>
          <Box component='div' sx={{ mt:'2em', mb:2, display:'flex', alignItems:'center' }}>
              <Typography variant='h5'>LISTADO DE TAREAS</Typography>
              <Tooltip title='Nueva Tarea'>
              <Fab size='small' sx={{ mx:5, p:2, backgroundColor: colors.primary, color: colors.secondary, '&:hover':{backgroundColor:colors.secondary, color:colors.primary} }} color='primary' onClick={handleOpen}><AddIcon/></Fab>
              </Tooltip>
          </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
          <TextField
              placeholder='Buscar Cliente'
              size='small'
              onChange={handleChangeSearch}
              fullWidth
          />
      </Grid>
      <Grid item width={'100%'}>
        <Paper sx={{ width:'100%', overflow:'hidden', mt:2 }} elevation={10}>
          <TableContainer>
            <Table stickyHeader aria-label="Tabla de tareas" >
              <TableHead >
                <TableRow >
                  {
                    columns.map((column)=>(
                      <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth:column.minWidth}}
                          sx={{ backgroundColor:'black', color:'white',fontWeight:'bold', borderColor:'white',p:1 }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                  { tasks.length > 0 &&  tasks.sort((a,b) => {
                        return b.taskNumber - a.taskNumber
                      })
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row)=>{
                        return(
                          <TableRow hover tabIndex={-1} key={row._id} >
                            <TableCell align='center'>{row.taskNumber}</TableCell>
                            <TableCell align='center'>{row.title}</TableCell>
                            <TableCell align='center'>{row.client.company}</TableCell>
                            <TableCell align='center'>{dayjs(row.creationDate).format('DD/MM/YYYY')}</TableCell>
                            <TableCell align='center'>{`${dayjs(row.expirationDate).format('DD/MM/YYYY')} (${dayjs(row.expirationDate).diff(dayjs(),'days')} dias)`}</TableCell>
                            <TableCell align='center'>{row.description}</TableCell>
                            <TableCell align='center'><ProgressBar progress={row.progress} /></TableCell>
                            <TableCell align='center'><Chip size='small' label={statusLabels[row.state]} color={row.state==='Finalizado'?'success':row.state==='Nuevo'?'error':'warning'}/></TableCell>
                            <TableCell align='center'>
                              <ButtonGroup size='small'>
                                <Tooltip title="Editar">
                                  <IconButton color='secondary' onClick={()=>handleClickEdit(row._id)} size='small'>
                                    <EditIcon/>
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Eliminar">
                                  <IconButton color='error' onClick={()=>handleClickDelete(row._id)} size='small'>
                                    <DeleteIcon/>
                                  </IconButton>
                                </Tooltip>
                              </ButtonGroup>
                              </TableCell>
                          </TableRow>
                        )
                      })
                  
                  }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
              rowsPerPageOptions={[10,25,100]}
              component ="div"
              count={tasks.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage='Servicios por página'
              labelDisplayedRows={({from,to,count})=>{ return `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`}}
          />
        </Paper>

        </Grid>
    </Grid>
  )
}

export default TasksList