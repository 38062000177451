import { Alert, Avatar, Box, Collapse, Grid, Paper, Stack, TextField, Typography } from '@mui/material'
import React, { useRef,useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ButtonZaz from '../ButtonZaz/ButtonZaz';
import { ThemeContext } from '../../context/ThemeContext';
import {useFormik} from 'formik';
import * as yup from 'yup';

import useAuth from '../../hooks/useAuth';
import axios from '../../api/axios';

const LOGIN_URL = '/users/login';
const validationSchema = yup.object({
    username: yup
        .string('Ingrese usuario')
        .required('Es necesario que ingrese su usuario'),
    password: yup
        .string('Ingrese su contraseña')
        .min(6,'Password debe tener 6 caracteres como mínimo')
        .required('Es necesario que ingrese su password')
})

const LoginForm = () => {
    const {colors}=useContext(ThemeContext)
    const navigate = useNavigate();
    const [open, setOpen]=useState(false);
    const [message, setMessage]=useState("");

    const {setAuth} = useAuth();
    const location = useLocation();

    const from = location.state?.from?.pathname || "/";

    const formik = useFormik({
        initialValues:{
            username:'',
            password:'',
        },
        validationSchema: validationSchema,
        onSubmit: async (values)=>{
                const result = await axios.post(LOGIN_URL,JSON.stringify(values,null,2), {withCredentials:true, headers:{'Access-Control-Allow-Origin':'*', 'Content-Type':'application/json'}})
                .catch((err)=>{
                const errorMessage="Intente nuevamente, Usuario o Contraseña incorrecta"
                setOpen(true)
                setMessage(errorMessage)
            })
            if(result.data.success){
                
                const accessToken = result?.data?.token;
                const firstName = result?.data?.firstName;
                setAuth({accessToken, firstName})
                navigate(from, {replace:true});
            } 
        }
    })
    
  return (
    <>
        <Paper elevation={20} sx={{ justifyContent:'center', padding:5, mt:20, opacity:0.9 }}>
            <Grid align='center'>
                <Avatar sx={{bgcolor:colors.primary}}>
                    <Box sx={{  height:40 }} component='img' src='/assets/images/imagotipo-negro-foco.svg'></Box>
                </Avatar>
                <Typography variant='h5' >Sistema de Gestión</Typography>
            </Grid>
            <Grid container>
                <Grid item xs={12}>

                <Box component={'form'} onSubmit={formik.handleSubmit}>
                    <Stack spacing={3} sx={{ mt:3 }}>
                        <TextField
                        id="username"
                        name="username"
                        label="Nombre de Usuario"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        error={formik.touched.username && Boolean(formik.errors.username)}
                        helperText={formik.touched.username && formik.errors.username}
                        autoComplete="on"
                        fullWidth
                        required
                        />
                        <TextField
                        id="password"
                        name="password"
                        label="Contraseña"
                        type="password"
                        autoComplete="on"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        fullWidth
                        required
                        />
                        <ButtonZaz type="submit">INICIAR</ButtonZaz>
                        
                        <Collapse in={open}>
                            <Alert
                            severity="error" variant="outlined"
                            sx={{ mb: 2 }}
                            onClose={()=>{
                                setOpen(false)
                            }}
                            >
                            {message}
                            </Alert>
                        </Collapse>
                    </Stack>
                </Box>
                </Grid>

            </Grid>
        </Paper>
    </>
  )
}

export default LoginForm