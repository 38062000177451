import React from 'react'
import { Container, Box, Typography, Button, Divider, Fab, Tooltip } from '@mui/material'
import ServiceListContainer from '../components/ServiceListContainer/ServiceListContainer'

const Services = () => {
  return (
    <Container maxWidth='xl'>
        <Box mt={2}>
          <ServiceListContainer/>
        </Box>
    </Container>
  )
}

export default Services