import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../context/UserContext'
import Loading from '../Loading/Loading';
import BoxModal from '../BoxModal/BoxModal'
import { ThemeContext } from '../../context/ThemeContext';
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Modal, Typography } from '@mui/material'
import AlarmIcon from '@mui/icons-material/Alarm';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ServiceList from '../ServiceList/ServiceList';
import ServiceForm from '../Form/ServiceForm';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';



const initialValue={
    state:'active',
  }
const statusColors={
    active:'#A7FF9B',
    expiring:'#FFF69B',
    expired:'#FFA69B'
}
const statusLabels={
    active:'ACTIVO',
    expiring:'PRÓXIMO VENCIMIENTO',
    expired:'VENCIDO'
}

const columns=[
    {id: 'title', label: 'Titulo'},
    {id: 'product', label: 'Producto'},
    {id: 'client', label: 'Cliente', align:'center'},
    {id: 'initDate', label: 'Fecha de Inicio'},
    {id: 'expirationDate', label: 'Fecha de Vencimiento'},
    {id: 'provider', label: 'Proveedor'},
    {id: 'state', label: 'Estado', align:'center'},
    {id: 'actions', label: 'Acciones', align:'center'},
]

const ServiceListContainer = () => {
    const {token} = useContext(UserContext)
    const axiosPrivate = useAxiosPrivate();
    const [search, setSearch]=useState('');
    const [services, setServices]=useState([]);
    const [filteredServices, setFilteredServices]=useState([]);
    const [filterType,setFilterType]=useState(()=>['hosting','dominio']);
    const [filterState, setFilterState]=useState(()=>['active','expiring','expired']);
    const [loading, setLoading]=useState(false)
    const [idService, setIdService]=useState()
    const [openDialog, setOpenDialog]=useState(false);
    const [open, setOpen]=useState(false);
    const [editMode, setEditMode] =useState(false);
    const [serviceData, setServiceData]=useState(initialValue);
    const [clients,setClients]=useState([]);
    const [products, setProducts]=useState([]);
    
    const handleChangeSearch = (e)=>{
        setSearch(e.target.value)
    }
    const handleChangeFilterType=(event,newFilter)=>{
        setFilterType(newFilter)
    }
    const handleChangeFilterState=(event,newFilter)=>{
        setFilterState(newFilter)
    }

  

    const getServices= async()=>{
        const res = await axiosPrivate.get(`/services`)
        if(res.data.success){
            
            setServices(res.data.data)
            setFilteredServices(res.data.data)
            setLoading(false)
            
        }
    }
    const getClients=async()=>{
        const res = await axiosPrivate.get(`/clients`)
        setClients(res.data.data)
    }
    const getProducts = async()=>{
        const res = await axiosPrivate.get(`/products`)
        setProducts(res.data.data)
    }
    const filterClient = async ()=>{
        let filteredRows= services
        search === '' ?
            filteredRows= services
        :
        (

           filteredRows = await services
                 .filter((row)=>{
                     return row.client?.company?.toLowerCase().indexOf(search.toLowerCase()) !== -1
                 })
            
        )
        
        
            if(filterType.length > 0){
                filteredRows = filteredRows.filter((row)=>{
                    return filterType.includes(row.product?.title?.toLowerCase().substring(0,7));
                })
            } 
            
            if(filterState.length >0 ){
                
                filteredRows=filteredRows.filter((row)=>{
                    return filterState.includes(row.state);
                })
            }
            setFilteredServices (filteredRows)
            
            setLoading(false)
    }
    const getService = async(id)=>{
        try{
            const res = await axiosPrivate.get(`/services/${id}`)
            if(res){
                setServiceData(res.data.data)
                setLoading(false)
                setOpen(true)
            }
        }catch(error){
            console.log(error)
        }
    }

    const handleCloseOpenDialog=()=>{
        setOpenDialog(false)
      }
    
    const handleClickEdit= (id)=>{
        setEditMode(true)
        setIdService(id)
        getService(id)
      }
    const handleClickDelete=(id)=>{
        setIdService(id)
        setOpenDialog(true)
      }

    const handleClickAlarm = (id)=>{
        setIdService(id)
        toggleAlarm();
    }

    const handleOpen=(state)=>{
        setEditMode(false)
        setOpen(true)};

    const handleClose=()=>{
        getServices();
        setServiceData(initialValue)
        setOpen(false)
        };
    const handleDelete= async()=>{
        try {
        const result = await axiosPrivate.delete(`/services/${idService}`)
        handleCloseOpenDialog()
        getServices();
        } catch (error) {
        console.log("ERROR BORRADO", error)      
        }
    }
    const sendNotify= async (id)=>{
        const result = await axiosPrivate.post(`/admin/sendMail`,{id})
        if(result){
            alert("MENSAJE ENVIADO CORRECTAMENTE")
        }
    }
    const toggleAlarm = async (id, state)=>{
        const alarm=!state
        const result = await axiosPrivate.put(`/services/${id}`,JSON.stringify({alarm: alarm}))
            .catch((err)=>{
                const errorMessage="Intente nuevamente"
                console.log(errorMessage)
                })
            if(result.data.message){
                getServices();
            } 
    }

    useEffect(()=>{
        setLoading(true)
        getClients()
        getProducts()
        getServices()
    },[])
    
    useEffect(()=>{
        filterClient()
    },[search, filterType, filterState])

    
  return (
    <>
    {
        loading ? 
        <Box sx={{ display:'flex', textAlign:'center', justifyContent:'center', height:'50vh'  }}>
            <Loading />
        </Box>
        :
        <>
            <Grid container>
                <ServiceList 
                handleChangeSearch={handleChangeSearch}
                filterType={filterType}
                handleChangeFilterType={handleChangeFilterType}
                filterState={filterState}
                handleChangeFilterState={handleChangeFilterState}
                columns={columns}
                services={filteredServices}
                statusLabels={statusLabels}
                handleClickEdit={handleClickEdit} 
                handleClickDelete={handleClickDelete} 
                handleOpen={handleOpen}
                toggleAlarm={toggleAlarm}
                sendNotify={sendNotify}

                />
            </Grid>
        </>
    }
        <Dialog
        open={openDialog}
        onClose={handleCloseOpenDialog}
    >
        <DialogTitle>
        {`Desea eliminar el servicio?`}
        </DialogTitle>
        <DialogContent>
        <DialogContentText>Si lo elimina no podrá verlo nuevamente</DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleCloseOpenDialog} autoFocus>NO</Button>
        <Button onClick={handleDelete}>SI</Button>
        </DialogActions>

    </Dialog>

    <Modal
        open={open}
        onClose={handleClose}
    >
        <BoxModal>
            <ServiceForm actionClose={handleClose} serviceData={serviceData} editMode={editMode} clients={clients} products={products} />
        </BoxModal>
    </Modal>
                
    </> 
        
  )
}

export default ServiceListContainer