import {Modal,  Stack, TableContainer, Fab, Box, Typography, TextField,Tooltip, Table, TableHead, TableBody, TableRow, TableCell, ButtonGroup, IconButton, Grid } from '@mui/material'
import React,{useContext, useState} from 'react'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import BoxModal from '../BoxModal/BoxModal';
import { ThemeContext } from '../../context/ThemeContext';
import ClientCard from '../ClientCard/ClientCard'

const ClientList = ({clients,handleClickEdit,handleClickDelete, handleOpen, handleChangeSearch}) => {
    const {colors}=useContext(ThemeContext)
  return (
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Box component='div' sx={{ mt:'2em', mb:2, display:'flex', alignItems:'center' }}>
                <Typography variant='h5'>LISTADO DE CLIENTES</Typography>
                <Tooltip title='Nuevo Cliente'>
                    <Fab size='small' sx={{ mx:5, p:2,backgroundColor: colors.primary, color: 'black', '&:hover':{backgroundColor:colors.secondary, color:colors.primary} }} onClick={handleOpen}><AddIcon/></Fab>
                </Tooltip>
            </Box>
        </Grid>
        <Grid item sx={12} sm={12}>
            <TextField
                placeholder='Buscar Cliente'
                size='small'
                onChange={handleChangeSearch}
                fullWidth
            />
        </Grid>
        <Grid item xs={12}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Empresa</TableCell>
                            <TableCell>Cliente</TableCell>
                            <TableCell>Estado</TableCell>
                            <TableCell>Telefono</TableCell>
                            <TableCell>Correo</TableCell>
                            <TableCell>Website</TableCell>
                            <TableCell>Servicios</TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            clients.map((client, index)=>(
                                <TableRow key={index}>
                                    <ClientCard data={client} handleClickEdit={handleClickEdit} handleClickDelete={handleClickDelete}/>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>

        </Grid>
    </Grid>
  )
}

export default ClientList