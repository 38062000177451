import { Alert, Avatar, Box, Collapse, Fab, Grid, MenuItem, Paper, Stack, TextField, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import ButtonZaz from '../ButtonZaz/ButtonZaz';
import { ThemeContext } from '../../context/ThemeContext';
import axios from 'axios';
import {useFormik} from 'formik';
import * as yup from 'yup';
import { UserContext } from '../../context/UserContext';
import LogoBackground from '../LogoBackground/LogoBackground';
import CloseIcon from '@mui/icons-material/Close';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
const validationSchema = yup.object({
    firstName: yup
        .string('Ingrese Nombre')
        .required('Es necesario que ingrese su nombre'),
    lastName: yup
        .string('Ingrese Apellido')
        .required('Es necesario que ingrese su apellido'),
    email: yup
        .string('Ingrese Correo Electronico')
        .required('Es necesario que ingrese su email'),
    company: yup
        .string('Ingrese Empresa')
        .required('Es necesario que ingrese su empresa'),
    url: yup
        .string('Ingrese el sitio'),
    phoneNumber: yup
        .string('Ingrese su telefono')
        .required('Es necesario que ingrese su telefono'),
    
},{timestamps:true})

const ClientForm = ({data,actionClick, actionClose, editMode, clientData}) => {
    const {colors}=useContext(ThemeContext)
    const [open, setOpen]=useState(false);
    const [message, setMessage]=useState("");
    const axiosPrivate = useAxiosPrivate();
    
      

    const formik = useFormik({
        initialValues: clientData,
        validationSchema: validationSchema,
        onSubmit: async (values)=>{
                if(!editMode){
                    const result = await axiosPrivate.post(`/clients/`,JSON.stringify(values,null,2))
                    .catch((err)=>{
                    const errorMessage="Intente nuevamente"
                    setOpen(true)
                    setMessage(errorMessage)
                    })
                    if(result.data.success){
                       actionClose()
                    } 
                }else{
                    const result = await axiosPrivate.put(`/clients/${values._id}`,JSON.stringify(values,null,2))
                    .catch((err)=>{
                        const errorMessage="Intente nuevamente"
                        setOpen(true)
                        setMessage(errorMessage)
                        })
                    if(result.data.message){
                        actionClose()
                    } 
                }
 
        }
    })
  return (
    <>
    <Box component='form' >
        <Fab size='small' sx={{ position:'absolute', top:0, right:0, m:2, p:2,backgroundColor: colors.primary, color: 'black', '&:hover':{backgroundColor:colors.secondary, color:colors.primary} }} onClick={()=>actionClose()}><CloseIcon/></Fab>
    <LogoBackground/>
    </Box>
        <Paper elevation={20} sx={{ justifyContent:'center', padding:2,   }}>
            <Grid align='center'>
                <Avatar sx={{bgcolor:colors.primary}}>
                    <Box sx={{  height:40 }} component='img' src='/assets/images/logo-sin-fondo.svg'></Box>
                </Avatar>
                <Typography align='center' variant='h5'py={2} fullWidth>{editMode?"EDITAR CLIENTE": "NUEVO CLIENTE"}</Typography>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                <Box component={'form'} onSubmit={formik.handleSubmit}>
                    <Stack spacing={2} sx={{ mt:3 }}>
                        <TextField
                        id="company"
                        name="company"
                        label="Empresa"
                        value={formik.values.company}
                        onChange={formik.handleChange}
                        error={formik.touched.company && Boolean(formik.errors.company)}
                        helperText={formik.touched.company && formik.errors.company}
                        autoComplete="on"
                        fullWidth
                        required
                        size="small"
                        />
                        <TextField
                            id="firstName"
                            name="firstName"
                            label="Nombre"
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                            helperText={formik.touched.firstName && formik.errors.firstName}
                            autoComplete="on"
                            fullWidth
                            required
                            size="small"
                        />
                        <TextField
                            id="lastName"
                            name="lastName"
                            label="Apellido"
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                            helperText={formik.touched.lastName && formik.errors.lastName}
                            autoComplete="on"
                            fullWidth
                            size="small"
                        />
                        <TextField
                        id="email"
                        name="email"
                        label="Correo Electrónico"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        autoComplete="on"
                        fullWidth
                        required
                        size="small"
                        />
                        <TextField
                        id="phoneNumber"
                        name="phoneNumber"
                        label="Teléfono"
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                        autoComplete="on"
                        fullWidth
                        required
                        size="small"
                        />
                        <TextField
                        id="url"
                        name="url"
                        label="Página Web"
                        value={formik.values.url}
                        onChange={formik.handleChange}
                        error={formik.touched.url && Boolean(formik.errors.url)}
                        helperText={formik.touched.url && formik.errors.url}
                        autoComplete="on"
                        fullWidth
                        size="small"
                        />
                        {
                            editMode &&
                            <TextField
                                id="state"
                                name="state"
                                label="Estado"
                                value={formik.values.state}
                                onChange={formik.handleChange}
                                error={formik.touched.state && Boolean(formik.errors.state)}
                                helperText={formik.touched.state && formik.errors.state}
                                autoComplete="on"
                                fullWidth
                                size="small"
                                select
                            >
                                <MenuItem value='new'>NUEVO</MenuItem>
                                <MenuItem value='active'>ACTIVO</MenuItem>
                                <MenuItem value='withdrawn'>BAJA</MenuItem>
                            </TextField>
                        }
                        <ButtonZaz type="submit">{editMode?"EDITAR":"NUEVO"} CLIENTE</ButtonZaz>
                        
                        <Collapse in={open}>
                            <Alert
                            severity="error" variant="outlined"
                            sx={{ mb: 2 }}
                            onClose={()=>{
                                setOpen(false)
                            }}
                            >
                            {message}
                            </Alert>
                        </Collapse>
                    </Stack>
                </Box>
                </Grid>

            </Grid>
        </Paper>
    </>
  )
}

export default ClientForm