import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Modal, Typography } from '@mui/material'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../context/UserContext'
import PriceList from '../PriceList/PriceList';
import Loading from '../Loading/Loading';
import BoxModal from '../BoxModal/BoxModal'
import ProductForm from '../Form/ProductForm';
import * as yup from 'yup';
import { ConfigContext } from '../../context/ConfigContext';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';


const initialValue={
    title:'',
    description:'',
    priceNational:0,
    priceInternational:0
  
  }

const PriceListContainer = () => {
    const {token} = useContext(UserContext)
    const {cotizacion} = useContext(ConfigContext)
    const axiosPrivate = useAxiosPrivate()
    const [products,setProducts]=useState([])
    const [loading,setLoading]=useState(false)
    const [idProduct, setIdProduct]=useState()
    const [openDialog, setOpenDialog]=useState(false);
    const [open, setOpen]=useState(false);
    const [editMode, setEditMode] =useState(false);
    const [productData, setProductData]=useState(initialValue);


    const getProducts = async ()=>{
        const res = await axiosPrivate.get(`/products`)
        if(res.data.success){
            
            setProducts(res.data.data)
            setLoading(false)
        }

    }
    const getProduct = async (id)=>{
        try {
            const productResult = await axiosPrivate.get(`/products/${id}`)
            if(productResult){
                productResult.data.data.priceNational=productResult.data.data.priceNational * cotizacion
                 setProductData(productResult.data.data)
                 setLoading(false)
                 setOpen(true)
            }
        } catch (error) {
            
        }

    }
    const handleCloseOpenDialog=()=>{
        setOpenDialog(false)
      }
    

    const handleClickEdit= (id)=>{
        //setLoading(true)
        setEditMode(true)
        setIdProduct(id)
        getProduct(id)
      }
    const handleClickDelete=(id)=>{
        setIdProduct(id)
        setOpenDialog(true)
      }
      const handleOpen=(state)=>{
        setEditMode(false)
        setOpen(true)};
    const handleClose=()=>{
        getProducts();
        setProductData(initialValue)
        setOpen(false)
        };
    const handleDelete= async()=>{
        try {
        const result = await axiosPrivate.delete(`/products/${idProduct}`)
        handleCloseOpenDialog()
        getProducts();
        } catch (error) {
        console.log("ERROR BORRADO", error)      
        }
    }
        
    useEffect(()=>{
        setLoading(true)
        
        getProducts()
    },[])
  return (
    <>
        {
            loading ? 
            <Box sx={{ display:'flex', textAlign:'center', justifyContent:'center', height:'50vh'  }}>
                <Loading />
            </Box>
            :
            <>
                <Grid container>
                    <PriceList prices={products} handleClickEdit={handleClickEdit} handleClickDelete={handleClickDelete} handleOpen={handleOpen}/>
                </Grid>
            </>
        }
        <Dialog
        open={openDialog}
        onClose={handleCloseOpenDialog}
      >
        <DialogTitle>
          {`Desea eliminar el producto?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Si lo elimina no podrá ingresar nuevamente</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOpenDialog} autoFocus>NO</Button>
          <Button onClick={handleDelete}>SI</Button>
        </DialogActions>

      </Dialog>

      <Modal
          open={open}
          onClose={handleClose}
      >
          <BoxModal>
            <ProductForm actionClose={handleClose} productData={productData} editMode={editMode} cotizacion={cotizacion}/>
          </BoxModal>
      </Modal>
                
    </>

    
  )
}

export default PriceListContainer