import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../context/UserContext'
import Loading from '../Loading/Loading';
import BoxModal from '../BoxModal/BoxModal'
import { ThemeContext } from '../../context/ThemeContext';
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Modal, Typography } from '@mui/material'
import AlarmIcon from '@mui/icons-material/Alarm';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import TasksList from '../TasksList/TasksList';
import TaskForm from '../Form/TaskForm';
import dayjs from 'dayjs';



const initialValue={
    state:'Nuevo',
    progress: '0',
    creationDate: dayjs().format('YYYY-MM-DD'),
    expirationDate: dayjs().add(7,'day').format('YYYY-MM-DD'),
  }
const statusColors={
    new:'#A7FF9B',
    expiring:'#FFF69B',
    expired:'#FFA69B'
}
const statusLabels={
    Nuevo:'NUEVO',
    Trabajando:'EN PROCESO',
    Finalizado:'FINALIZADO'
}

const COLUMNS=[
    {id: 'taskNumber', label: '#', align:'center'},
    {id: 'title', label: 'Titulo', align:'center'},
    {id: 'client', label: 'Cliente', align:'center'},
    {id: 'creationDate', label: 'Fecha de Inicio', align:'center'},
    {id: 'expirationDate', label: 'Fecha de Vencimiento', align:'center'},
    {id: 'description', label: 'Descripcion', align:'center'},
    {id: 'progress', label: 'Progreso', align:'center'},
    {id: 'state', label: 'Estado', align:'center'},
    {id: 'actions', label: 'Acciones', align:'center'},
]


const TasksListContainer = () => {
  const {token} = useContext(UserContext)
  const axiosPrivate = useAxiosPrivate();
  const [search, setSearch]=useState('');
  const [tasks, setTasks]=useState([]);
  const [filteredTasks, setFilteredTasks]=useState([]);
  const [filterType,setFilterType]=useState(()=>['hosting','dominio']);
  const [filterState, setFilterState]=useState(()=>['active','expiring','expired']);
  const [loading, setLoading]=useState(false)
  const [idTask, setIdTask]=useState()
  const [openDialog, setOpenDialog]=useState(false);
  const [open, setOpen]=useState(false);
  const [editMode, setEditMode] =useState(false);
  const [taskData, setTaskData]=useState(initialValue);
  const [clients,setClients]=useState([]);
  const [products, setProducts]=useState([]);

  const handleChangeSearch = (e)=>{
    setSearch(e.target.value)
  }
  const handleChangeFilterType=(event,newFilter)=>{
      setFilterType(newFilter)
  }
  const handleChangeFilterState=(event,newFilter)=>{
      setFilterState(newFilter)
  }



  const getTasks= async()=>{
      const res = await axiosPrivate.get(`/tasks`)
      if(res.data.success){
          setTasks(res.data.data)
          setFilteredTasks(res.data.data)
          setLoading(false)
          
      }
  }
  const getClients=async()=>{
      const res = await axiosPrivate.get(`/clients`)
      setClients(res.data.data)
  }
  const getProducts = async()=>{
      const res = await axiosPrivate.get(`/products`)
      setProducts(res.data.data)
  }
  const filterClient = async ()=>{
      let filteredRows= tasks
      search === '' ?
          filteredRows= tasks
      :
      (

        filteredRows = await tasks
              .filter((row)=>{
                  return row.client?.company?.toLowerCase().indexOf(search.toLowerCase()) !== -1
              })
          
      )
      
      
          if(filterType.length > 0){
              filteredRows = filteredRows.filter((row)=>{
                  return filterType.includes(row.product?.title?.toLowerCase().substring(0,7));
              })
          } 
          
          if(filterState.length >0 ){
              
              filteredRows=filteredRows.filter((row)=>{
                  return filterState.includes(row.state);
              })
          }
          setFilteredTasks (filteredRows)
          
          setLoading(false)
  }
  const getTask = async(id)=>{
      try{
          const res = await axiosPrivate.get(`/tasks/${id}`)
          if(res){
              setTaskData(res.data.data)
              setLoading(false)
              setOpen(true)
          }
      }catch(error){
          console.log(error)
      }
  }

  const handleCloseOpenDialog=()=>{
      setOpenDialog(false)
    }

  const handleClickEdit= (id)=>{
      setEditMode(true)
      setIdTask(id)
      getTask(id)
    }
  const handleClickDelete=(id)=>{
      setIdTask(id)
      setOpenDialog(true)
    }

  const handleOpen=(state)=>{
      setEditMode(false)
      setOpen(true)};

  const handleClose=()=>{
      getTasks();
      setTaskData(initialValue)
      setOpen(false)
      };
  const handleDelete= async()=>{
      try {
      const result = await axiosPrivate.delete(`/tasks/${idTask}`)
      handleCloseOpenDialog()
      getTasks();
      } catch (error) {
      console.log("ERROR BORRADO", error)      
      }
  }

  useEffect(()=>{
    setTaskData(initialValue)
      setLoading(true)
      getClients()
      //getProducts()
      getTasks()
  },[])

  useEffect(()=>{
      filterClient()
  },[search, filterType, filterState])


  return (
    <>
    {
        loading ? 
        <Box sx={{ display:'flex', textAlign:'center', justifyContent:'center', height:'50vh'  }}>
            <Loading />
        </Box>
        :
        <>
            <Grid container width={'100%'}>
              <TasksList 
                handleChangeSearch={handleChangeSearch}
                filterType={filterType}
                handleChangeFilterType={handleChangeFilterType}
                filterState={filterState}
                handleChangeFilterState={handleChangeFilterState}
                columns={COLUMNS}
                tasks={filteredTasks}
                statusLabels={statusLabels}
                handleClickEdit={handleClickEdit} 
                handleClickDelete={handleClickDelete} 
                handleOpen={handleOpen}
                />
            </Grid>
        </>
    }
        <Dialog
        open={openDialog}
        onClose={handleCloseOpenDialog}
    >
        <DialogTitle>
        {`Desea eliminar el servicio?`}
        </DialogTitle>
        <DialogContent>
        <DialogContentText>Si lo elimina no podrá verlo nuevamente</DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleCloseOpenDialog} autoFocus>NO</Button>
        <Button onClick={handleDelete}>SI</Button>
        </DialogActions>

    </Dialog>

    <Modal
        open={open}
        onClose={handleClose}
    >
        <BoxModal>
            <TaskForm actionClose={handleClose} taskData={taskData} editMode={editMode} clients={clients} products={products} />
        </BoxModal>
    </Modal>
                
    </> 
        
  )
}

export default TasksListContainer