import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Modal, Typography } from '@mui/material'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../context/UserContext'
import Loading from '../Loading/Loading';
import BoxModal from '../BoxModal/BoxModal'
import { ThemeContext } from '../../context/ThemeContext';
import TransactionList from '../TransactionList/TransactionList'
import TransactionForm from '../Form/TransactionForm';
import dayjs from 'dayjs';
import { setNestedObjectValues } from 'formik';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const initialValue={
  date:dayjs().format('YYYY-MM-DD')
}

const TransactionListContainer = () => {
    const {colors}=useContext(ThemeContext)
    const axiosPrivate = useAxiosPrivate()
    const [transactions, setTransactions]=useState([])
    const [loading, setLoading]=useState(false)
    const [idTransaction, setIdTransaction]=useState()
    const [openDialog, setOpenDialog]=useState(false);
    const [open, setOpen]=useState(false);
    const [editMode, setEditMode] =useState(false);
    const [sales,setSales]=useState([]);
    const [transactionData, setTransactionData]=useState(initialValue);
    
    const getTransactions = async ()=>{
      const res = await axiosPrivate.get(`/transactions`)
      if(res.data.success){
        setTransactions(res.data.data.sort((a,b)=>{
          return dayjs(b.date).unix() - dayjs(a.date).unix()
        }))
        setLoading(false)
      }
    }

    const getTransaction = async(id)=>{
      try{
          const res = await axiosPrivate.get(`/transactions/${id}`)
          console.log('RES', res)
          if(res){
              setTransactionData(res.data.data)
              setLoading(false)
              setOpen(true)
          }
      }catch(error){
          console.log(error)
      }
  }


    const getSales = async()=>{
      const res = await axiosPrivate.get(`/sales`)
      if(res.data.success){
        setSales(res.data.data)
      }
    }
    const handleCloseOpenDialog=()=>{
      setOpenDialog(false)
    }
    const handleClickDelete=(id)=>{
      setIdTransaction(id)
      setOpenDialog(true)
    }

    const handleClickEdit= (id)=>{
      setEditMode(true)
      setIdTransaction(id)
      getTransaction(id)
    }
    const handleOpen=(state)=>{
      setEditMode(false)
      setOpen(true)};
    const handleClose=()=>{
      getTransactions();
      setTransactionData(initialValue)
      setOpen(false)
      };
    
      const handleDelete= async()=>{
        try {
        const result = await axiosPrivate.delete(`/transactions/${idTransaction}`)
        handleCloseOpenDialog()
        getTransactions();
        } catch (error) {
        console.log("ERROR BORRADO", error)      
        }
    }
  useEffect(()=>{
    setLoading(true)
    getSales()
    getTransactions()
  },[])

  return (
    <>
    {
      loading ? 
      <Box sx={{ display:'flex', textAlign:'center', justifyContent:'center', height:'50vh'  }}>
          <Loading />
      </Box>
      :
      <>
          <Grid container>
            <TransactionList transactions={transactions} handleClickDelete={handleClickDelete} handleClickEdit={handleClickEdit} handleOpen={handleOpen}/>
          </Grid>
      </>
    }

<Dialog
    open={openDialog}
    onClose={handleCloseOpenDialog}
  >
    <DialogTitle>
      {`Desea eliminar el cliente?`}
    </DialogTitle>
    <DialogContent>
      <DialogContentText>Si lo elimina no podrá verlo nuevamente</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseOpenDialog} autoFocus>NO</Button>
      <Button onClick={handleDelete}>SI</Button>
    </DialogActions>

  </Dialog>

  <Modal
      open={open}
      onClose={handleClose}
  >
      <BoxModal>
        <TransactionForm actionClose={handleClose} transactionData={transactionData} editMode={editMode} sales={sales} />
      </BoxModal>
  </Modal>
    </>
   
  )
}

export default TransactionListContainer