import { Box, Container } from '@mui/material'
import React from 'react'
import SalesListContainer from '../components/SalesListContainer/SalesListContainer'

const Sales = () => {
  return (
    <Container maxWidth='100%'>
        <Box mt={2}>
          <SalesListContainer/>
        </Box>
    </Container>
  )
}

export default Sales