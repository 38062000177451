import {  Box, Grid} from '@mui/material'
import React, { useContext } from 'react'
import LoginForm from '../components/LoginForm/LoginForm';
import { ThemeContext } from '../context/ThemeContext';
import '../App.css'

const Login = () => {
    const {colors}=useContext(ThemeContext)
  return (
    <Box sx={{ height:'100vh', backgroundColor:colors.primary }} className="form-container">
        <Grid container>
            <Grid item >
                    <Box sx={{ position:'absolute', zIndex:1, align:'center', left:{sm:'50%', xs:'3%'} }} >
                        <LoginForm/>
                    </Box>
            </Grid>
        </Grid>
    </Box>
  )
}

export default Login