import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider } from './context/AuthProvider';

import { BrowserRouter,Routes,Route } from 'react-router-dom';
import ConfigProvider from './context/ConfigContext';
import UserProvider from './context/UserContext';
import ThemeProvider from './context/ThemeContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <ConfigProvider>
            <UserProvider>
              <ThemeProvider>
                <Routes>
                  <Route path="/*" element={<App />}/>
                </Routes>
              </ThemeProvider>
            </UserProvider>
          </ConfigProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

