import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Modal, Typography } from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { UserContext } from '../../context/UserContext'
import Loading from '../Loading/Loading';
import BoxModal from '../BoxModal/BoxModal'
import { ThemeContext } from '../../context/ThemeContext'
import SalesList from '../SalesList/SalesList'
import SaleForm from '../Form/SaleForm';
import dayjs from 'dayjs';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const initialValue={
 totalPrice:0,
 state:'pending',
 discount:0,
 creationDate:dayjs().format("YYYY-MM-DD",{timeZone:'America/Argentina/Mendoza'})
}

const SalesListContainer = () => {
    const axiosPrivate = useAxiosPrivate()
    const [sales,setSales]=useState([])
    const [filteredSales, setFilteredSales]=useState([])
    const [clients, setClients]=useState([])
    const [products, setProducts]=useState([])
    const [search, setSearch]=useState('');
    const [filterState, setFilterState]=useState(()=>['ok','pending','rejected', 'send']);
    const [loading, setLoading]=useState(false)
    const [idSale, setIdSale]=useState()
    const [openDialog, setOpenDialog]=useState(false);
    const [open, setOpen]=useState(false);
    const [editMode, setEditMode] =useState(false);
    const [saleData, setSaleData]=useState(initialValue);
    const [openDialogEdit, setOpenDialogEdit]=useState(false);
    const [idEnviada, setIdEnviada]=useState()

    const handleChangeSearch = (e)=>{
        setSearch(e.target.value)
    }
    const handleChangeFilterState=(event,newFilter)=>{
        setFilterState(newFilter)
    }

    const filterSale = async()=>{
        let filteredRows = sales.filter((row)=>{
          
            return row.client.company.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
        })
        if(filterState.length >0 ){
            filteredRows=filteredRows.filter((row)=>{
                return filterState.includes(row.state);
            })
        }
         setFilteredSales(filteredRows)

    }

    const getSales = async()=>{
        const res = await axiosPrivate.get(`/sales`)
        if(res.data.success){
            
            setSales(res.data.data)
            setFilteredSales(res.data.data)
            
        }
        setLoading(false)
    }

    const getClients = async()=>{
      const res =await axiosPrivate.get(`/clients`)
      if(res.data.success){
        setClients(res.data.data)
      }
    }
    const getProducts = async()=>{
      const res = await axiosPrivate.get(`/products`)
      if(res.data.success){
        setProducts(res.data.data)
      }
    }

    const getSale = async(id)=>{
        try {
            const res=await axiosPrivate.get(`/sales/${id}`)
            if(res){
              
                setSaleData(res.data.data)
                setLoading(false)
                setOpen(true)
            }
        } catch (error) {
            console.log(error)
            
        }
    }
    const handleCloseOpenDialog=()=>{
        setOpenDialog(false)
      }
    
    const handleClickEdit= (id)=>{
        //setLoading(true)
        
        setEditMode(true)
        setIdSale(id)
        getSale(id)
        
      }
    const handleClickPrint=async(id)=>{
      const res=await axiosPrivate.get(`/sales/${id}`)
            if(res){
              const url=`${process.env.REACT_APP_API_ENDPOINT}/files/Presupuesto ${res.data.data.saleNumber}.pdf`
              window.open(url)
              return {success:true}
            }
    }
    const handleClickSend=async(id)=>{
        setOpenDialogEdit(false)
        const result = await axiosPrivate.post(`/sales/sendSaleMail`,{idEnviada})
        if(result){
            alert("MENSAJE ENVIADO CORRECTAMENTE")
        }

    }
    const handleClickDelete=(id)=>{
        setIdSale(id)
        setOpenDialog(true)
      }
    const handleOpen=(state)=>{
        setEditMode(false)
        setOpen(true)};

    const handleClose=()=>{
        setSaleData(initialValue)
        getSales();
        setOpen(false)
        };
    const handleDelete= async()=>{
        try {
        const result = await axiosPrivate.delete(`/sales/${idSale}`)
        handleCloseOpenDialog()
        getSales();
        } catch (error) {
        console.log("ERROR BORRADO", error)      
        }
    }

    const handleOpenDialogEdit = (id) => {
      setIdEnviada(id)
        setOpenDialogEdit(true)
    }

    const handleCloseOpenDialogEdit = () => {
        setOpenDialogEdit(false)
    }
    useEffect(()=>{
      getClients();
      getProducts();
        getSales();
        
    },[])

    useEffect(()=>{
        filterSale();
    },[search, filterState])
  return (

    <>
    {
        loading ? 
        <Box sx={{ display:'flex', textAlign:'center', justifyContent:'center', height:'50vh'  }}>
            <Loading />
        </Box>
        :
        <>
            <Grid container maxWidth="100%">
            <SalesList 
                sales={filteredSales}
                handleChangeSearch={handleChangeSearch}
                filterState={filterState}
                handleChangeFilterState={handleChangeFilterState}
                handleOpen={handleOpen}
                handleClickDelete={handleClickDelete}
                handleClickEdit={handleClickEdit}
                handleClickPrint={handleClickPrint}
                handleClickSend={handleOpenDialogEdit}
                />
            </Grid>
        </>
    }

    <Dialog
    open={openDialog}
    onClose={handleCloseOpenDialog}
  >
    <DialogTitle>
      {`Desea eliminar el Presupuesto?`}
    </DialogTitle>
    <DialogContent>
      <DialogContentText>Si lo elimina no podrá verlo nuevamente</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseOpenDialog} autoFocus>NO</Button>
      <Button onClick={handleDelete}>SI</Button>
    </DialogActions>
  </Dialog>

  <Dialog
    open={openDialogEdit}
    onClose={handleCloseOpenDialogEdit}
  >
    <DialogTitle>
      {`Desea enviar el Presupuesto?`}
    </DialogTitle>
    <DialogContent>
      <DialogContentText>Si lo envia no podrá revertir el envio</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseOpenDialogEdit} autoFocus>NO</Button>
      <Button onClick={handleClickSend}>SI</Button>
    </DialogActions>
  </Dialog>
    
  <Modal
      open={open}
      onClose={handleClose}
  >
      <BoxModal>
        <SaleForm actionClose={handleClose} saleData={saleData} editMode={editMode} clients={clients} products={products} />
      </BoxModal>
  </Modal>
            
</> 
 
        
 

    
  )
}

export default SalesListContainer