import {Outlet} from 'react-router-dom'
import Home from '../Home/Home'

const SidebarLayout = () => {
  return (
    <main className="App">
        <Home>
            <Outlet/>
        </Home>
       
    </main>
  )
}

export default SidebarLayout