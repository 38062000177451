import {createContext,useState} from 'react'

export const ConfigContext = createContext();

const ConfigProvider =({children})=>{
    const [cotizacion, setCotizacion]=useState()

    const changeCotizacion= (newCotizacion)=>{
        setCotizacion(newCotizacion);
    }
    return (
        <ConfigContext.Provider value={{ cotizacion, changeCotizacion }}>
            {children}
        </ConfigContext.Provider>
    )
}

export default ConfigProvider