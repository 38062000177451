import {
  Stack,
  TableContainer,
  Fab,
  Box,
  Typography,
  Paper,
  Tooltip,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  ButtonGroup,
  IconButton,
} from "@mui/material";
import React, { useContext } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ThemeContext } from "../../context/ThemeContext";
import { ConfigContext } from "../../context/ConfigContext";

const PriceList = ({
  prices,
  handleClickEdit,
  handleClickDelete,
  handleOpen,
}) => {
  const { colors } = useContext(ThemeContext);
  const { cotizacion } = useContext(ConfigContext);
  return (
    <Box>
      <Stack direction={"column"} spacing={2}>
        <Box
          component="div"
          sx={{ mt: "2em", mb: 2, display: "flex", alignItems: "center" }}
        >
          <Typography variant="h5">LISTADO DE PRODUCTOS</Typography>
          <Tooltip title="Nuevo Producto">
            <Fab
              size="small"
              sx={{
                mx: 5,
                p: 2,
                backgroundColor: colors.primary,
                color: colors.secondary,
                "&:hover": {
                  backgroundColor: colors.secondary,
                  color: colors.primary,
                },
              }}
              color="primary"
              onClick={handleOpen}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </Box>
        <Typography variant="h6" sx={{ my: 2 }}>
          Cotizacion actual: ${cotizacion}
        </Typography>
        <TableContainer component={Paper} elevation={10}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  variant="head"
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    fontWeight: "bold",
                    borderColor: "white",
                    p: 1,
                  }}
                >
                  TITULO
                </TableCell>
                <TableCell
                  variant="head"
                  align="right"
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    fontWeight: "bold",
                    borderColor: "white",
                    p: 1,
                  }}
                >
                  PRECIO NACIONAL
                </TableCell>
                <TableCell
                  variant="head"
                  align="right"
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    fontWeight: "bold",
                    borderColor: "white",
                    p: 1,
                  }}
                >
                  PRECIO INTERNACIONAL
                </TableCell>
                <TableCell
                  variant="head"
                  align="center"
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    fontWeight: "bold",
                    borderColor: "white",
                    p: 1,
                  }}
                >
                  ACCIONES
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prices.map((row) => (
                <TableRow key={row._id} hover>
                  <TableCell>{row.title}</TableCell>
                  <TableCell align="right">
                    $ {(row.priceNational * cotizacion).toFixed(0)}
                  </TableCell>
                  <TableCell align="right">
                    US$ {row.priceInternational}
                  </TableCell>
                  <TableCell align="center">
                    <ButtonGroup>
                      <Tooltip title="Editar">
                        <IconButton
                          color="secondary"
                          onClick={() => handleClickEdit(row._id)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Eliminar">
                        <IconButton
                          color="error"
                          onClick={() => handleClickDelete(row._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Box>
  );
};

export default PriceList;
