import React, { useContext, useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import { Fab, Grid, Box, ButtonGroup, Chip, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography, Modal } from '@mui/material'
import AlarmIcon from '@mui/icons-material/Alarm';
import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import BoxModal from '../BoxModal/BoxModal';
import Form from '../Form/FormOld';
import { ThemeContext } from '../../context/ThemeContext';
import dayjs from 'dayjs';


const ServiceList = ({handleChangeSearch, filterType, handleChangeFilterType, filterState, handleChangeFilterState,columns, services, statusLabels, handleClickEdit, handleClickDelete, handleOpen, toggleAlarm, sendNotify}) => {
const {colors}=useContext(ThemeContext)
const [page, setPage]= useState(0);
const [rowsPerPage, setRowsPerPage]=useState(10);
const handleChangePage =(e,newPage)=>{
    setPage(newPage);
}
const handleChangeRowsPerPage=(e)=>{
    setRowsPerPage(e.target.value);
    setPage(0);
}


return (
    <Grid container>
        <Grid item md={12}>
            <Box component='div' sx={{ mt:'2em', mb:2, display:'flex', alignItems:'center' }}>
                <Typography variant='h5'>LISTADO DE SERVICIOS</Typography>
                <Tooltip title='Nuevo Servicio'>
                <Fab size='small' sx={{ mx:5, p:2, backgroundColor: colors.primary, color: colors.secondary, '&:hover':{backgroundColor:colors.secondary, color:colors.primary} }} color='primary' onClick={handleOpen}><AddIcon/></Fab>
                </Tooltip>
            </Box>
        </Grid>
        <Grid item sx={12} sm={6}>
            <TextField
                placeholder='Buscar Cliente'
                size='small'
                onChange={handleChangeSearch}
                fullWidth
            />
        </Grid>
        <Grid item>
            <Stack direction={'row'} spacing={3} sx={{ mt:2 }}>
                <Typography variant='subtitle1' alignItems={'center'}>Filtrar:</Typography>
                
                <ToggleButtonGroup 
                    size='small' 
                    value={filterType}
                    onChange={handleChangeFilterType}  
                    aria-label="type filtering"
                    >
                    <ToggleButton value='hosting' size='small' aria-label='hosting' color='primary'>
                        Hosting
                    </ToggleButton>
                    <ToggleButton value='dominio' size='small' aria-label='dominio' color='secondary'>
                        Dominio
                    </ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup size='small' 
                    value={filterState}
                    onChange={handleChangeFilterState}  
                    aria-label="state filtering"
                    >
                    <ToggleButton value='active' size='small' color='success'>
                        ACTIVO
                    </ToggleButton>
                    <ToggleButton value='expiring' size='small' color='warning'>
                        PRÓXIMO VENCIMIENTO
                    </ToggleButton>
                    <ToggleButton value='expired' size='small' color='error'>
                        VENCIDO
                    </ToggleButton>
                </ToggleButtonGroup>
            </Stack>

        </Grid>
        <Grid item>
            <Paper sx={{ width:'100%', overflow:'hidden', mt:2 }} elevation={10}>
                <TableContainer >
                    <Table stickyHeader aria-label="Tabla de servicios" >
                        <TableHead >
                            <TableRow >
                                {
                                    columns.map((column)=>(
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth:column.minWidth}}
                                            sx={{ backgroundColor:'black', color:'white',fontWeight:'bold', borderColor:'white',p:1 }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {services
                                .sort((a,b) => {
                                    return dayjs(a.expirationDate).unix() - dayjs(b.expirationDate).unix();
                                })
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row)=>{
                                    return(
                                        <TableRow hover tabIndex={-1} key={row._id} >
                                            <TableCell align='center'>{row.title}</TableCell>
                                            <TableCell align='center'>{row.product.title}</TableCell>
                                            <TableCell align='center'>{row.client.company}</TableCell>
                                            <TableCell align='right'>{dayjs(row.initDate).format('DD/MM/YYYY')}</TableCell>
                                            <TableCell align='center'>{`${dayjs(row.expirationDate).format('DD/MM/YYYY')} (${dayjs(row.expirationDate).diff(dayjs(),'days')} dias)`}</TableCell>
                                            <TableCell align='right'>{row.provider}</TableCell>
                                            <TableCell><Chip size='small' label={statusLabels[row.state]} color={row.state==='active'?'success':row.state==='expired'?'error':'warning'}/></TableCell>
                                            <TableCell>
                                                <ButtonGroup size='small'>
                                                    <Tooltip title="Programar">
                                                        <IconButton color={row.alarm ? 'success':'error'} onClick={()=>toggleAlarm(row._id, row.alarm )} size='small'>
                                                            <AlarmIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Notificar ahora">
                                                        <IconButton color='warning' onClick={()=>sendNotify(row._id)} size='small'>
                                                            <SendIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Editar">
                                                        <IconButton color='secondary' onClick={()=>handleClickEdit(row._id)} size='small'>
                                                            <EditIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Eliminar">
                                                        <IconButton color='error' onClick={()=>handleClickDelete(row._id)} size='small'>
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10,25,100]}
                    component ="div"
                    count={services.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage='Servicios por página'
                    labelDisplayedRows={({from,to,count})=>{ return `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`}}
                />
            </Paper>

        </Grid>
    </Grid>
    
  )
}

export default ServiceList