import {Avatar, Box, IconButton, Toolbar, Typography } from '@mui/material'
import React, { useContext } from 'react'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import {Link} from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import {ThemeContext} from '../../context/ThemeContext'
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const Navbar = ({handleDrawerToggle}) => {
  const {colors}=useContext(ThemeContext)
  const {auth} = useAuth(); 
  const navigate=useNavigate()
  const axiosPrivate = useAxiosPrivate();

  const handleClickLogout=async()=>{
    try {
      const result = await axiosPrivate.get(`/users/logout`)
      result.data.success?
      navigate('/login')
      :
      console.log("ERROR AL CERRAR SESION")
    } catch (error) {
      navigate('/login')
    }
  }

  return (
    <>
        <Toolbar sx={{ backgroundColor:colors.primary }} >
          <Box
          component="div" 
          sx={{ flexGrow:0, justifyContent:'start' }}
          >
            <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={()=>{handleDrawerToggle()}}
            sx={{ mr: 2, display: { sm: 'none', xs:'flex' }, color:colors.secondary }}
          >
             <MenuIcon />
          </IconButton>
            <Link to='/'>
              < Avatar variant={"rounded"} alt="The image" src='/assets/images/zaz-negro-pleno.svg' 
              sx={{
                width: 120,
                height: 48,
                display:{xs:'none', sm:'flex'}
              }}
              />
           
            </Link>
          </Box>
          <Box
          component="div" 
          sx={{ flexGrow:1, justifyContent:'start' }}
          >

          </Box>
          <Typography variant="overline" sx={{ color:"black", display: { xs: 'none', md:'block'} }}>Bienvenido {auth.firstName}</Typography>
          <IconButton sx={{ color:colors.secondary }} onClick={handleClickLogout}>
            <LogoutOutlinedIcon />
          </IconButton>
          
        </Toolbar>
    </>
  )
}

export default Navbar