import React, { useContext } from 'react'
import { Button } from '@mui/material'
import { ThemeContext } from '../../context/ThemeContext'

const ButtonZaz = ({children, onClick, type ="button"}) => {
  const {colors}=useContext(ThemeContext)
  return (
    <Button variant='contained' type="submit" fullWidth sx={{ backgroundColor:colors.secondary, '&:hover':{backgroundColor:colors.primary, color:colors.secondary} , color:'white'}} onClick={onClick}>
       {children}
    </Button>
  )
}

export default ButtonZaz