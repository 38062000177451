import { Avatar, CircularProgress, Box } from '@mui/material'
import React, { useContext } from 'react'
import { ThemeContext } from '../../context/ThemeContext'


const Loading = () => {
  const {colors}=useContext(ThemeContext)
  return (
    <>
    <Box sx={{ display:'flex',alignItems:'center' }}>
        <Box sx={{ m:1, position:'relative' }}>

            <Box component={'img'} src='/assets/images/logo-sin-fondo.svg' sx={{ backgroundColor:colors.primary, borderRadius:'100%', width:59 }}></Box>
            <CircularProgress size={68} sx={{ color:colors.secondary,position:'absolute', top:-3, left:-6, zIndex:1 }}/>
        </Box>

    </Box>
        
    </>
  )
}

export default Loading