import React from 'react'
import {Box, Container} from '@mui/material'
import DashboardContainer from '../components/Dashboard/DashboardContainer/DashboardContainer'

const Dashboard = () => {
  return (
    <Container maxWidth='xl'>
        <Box mt={2}>
          <DashboardContainer/>          
        </Box>
    </Container>
  )
}

export default Dashboard