import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  AppBar,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Drawer,
  Icon,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import Navbar from "../Navbar/Navbar";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import SettingsIcon from "@mui/icons-material/Settings";
import WebIcon from "@mui/icons-material/Web";
import GroupIcon from "@mui/icons-material/Group";

import LanguageIcon from "@mui/icons-material/Language";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SummarizeIcon from "@mui/icons-material/Summarize";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import { Link } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";
import { ConfigContext } from "../../context/ConfigContext";
import axios from "axios";
import Snackbar from "../Snackbar/Snackbar";
import HostingerIcon from "../../assets/hostingerLogo.svg";
import NetServicesIcon from "../../assets/logoNetServices.png";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { number } from "yup";

const drawerWidth = 200;

const EXTERNAL_LINKS = [
  {
    title: "Webmail",
    icon: <MailIcon />,
    link: "https://mail.hostinger.com/",
  },
  {
    title: "Hostinger",
    icon: (
      <img
        src={HostingerIcon}
        alt="Hostinger"
        width="24px"
        height="24px"
        style={{ opacity: 0.5 }}
      />
    ),
    link: "https://www.hostinger.com/cpanel-login",
  },
  {
    title: "NetServices AMPD",
    icon: (
      <img
        src={NetServicesIcon}
        alt="Hostinger"
        width="24px"
        style={{ opacity: 0.5 }}
      />
    ),
    link: "http://www.ampd.com.ar:2082/",
  },
  {
    title: "NetServices TiendaZAZ",
    icon: (
      <img
        src={NetServicesIcon}
        alt="NetServices"
        width="24px"
        style={{ opacity: 0.5 }}
      />
    ),
    link: "https://tiendazaz.com.ar:2083/",
  },
  {
    title: "Nic.ar",
    icon: <LanguageIcon />,
    link: "https://www.nic.ar/",
  },
];

const Home = ({ children }) => {
  const { cotizacion, changeCotizacion } = useContext(ConfigContext);
  const { colors } = useContext(ThemeContext);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <Link to={"/dashboard"}>
          <ListItem
            disablePadding
            sx={{
              "&:hover": {
                backgroundColor: colors.primary,
                color: colors.secondary,
              },
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary={"Dashboard"} />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to={"/clients"}>
          <ListItem
            disablePadding
            sx={{
              "&:hover": {
                backgroundColor: colors.primary,
                color: colors.secondary,
              },
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <ContactPageIcon />
              </ListItemIcon>
              <ListItemText primary={"Clientes"} />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to={"/tasks"}>
          <ListItem
            disablePadding
            sx={{
              "&:hover": {
                backgroundColor: colors.primary,
                color: colors.secondary,
              },
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary={"Tareas"} />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to={"/services"}>
          <ListItem
            disablePadding
            sx={{
              "&:hover": {
                backgroundColor: colors.primary,
                color: colors.secondary,
              },
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <WebIcon />
              </ListItemIcon>
              <ListItemText primary={"Servicios"} />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to={"/products"}>
          <ListItem
            disablePadding
            sx={{
              "&:hover": {
                backgroundColor: colors.primary,
                color: colors.secondary,
              },
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <SummarizeIcon />
              </ListItemIcon>
              <ListItemText primary={"Productos"} />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to={"/sales"}>
          <ListItem
            disablePadding
            sx={{
              "&:hover": {
                backgroundColor: colors.primary,
                color: colors.secondary,
              },
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <AttachMoneyIcon />
              </ListItemIcon>
              <ListItemText primary={"Presupuestos"} />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to={"/balance"}>
          <ListItem
            disablePadding
            sx={{
              "&:hover": {
                backgroundColor: colors.primary,
                color: colors.secondary,
              },
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText primary={"Finanzas"} />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to={"/users"}>
          <ListItem
            disablePadding
            sx={{
              "&:hover": {
                backgroundColor: colors.primary,
                color: colors.secondary,
              },
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary={"Usuarios"} />
            </ListItemButton>
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List>
        {EXTERNAL_LINKS.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{
              "&:hover": {
                backgroundColor: colors.primary,
                color: colors.secondary,
              },
            }}
          >
            <ListItemButton>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <a href={item.link} target="_blank" rel="noreferrer noopener">
                <ListItemText primary={item.title} />
              </a>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
  const getCotizacion = async () => {
    const res = await axios.get(
      "https://www.dolarsi.com/api/api.php?type=valoresprincipales"
    );
    changeCotizacion(
      parseFloat(res.data[1].casa.venta.replace(",", ".")) * 1000
    );
  };

  useEffect(() => {
    getCotizacion();
  }, []);
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Navbar handleDrawerToggle={handleDrawerToggle} />
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#F1EEE9",
              color: "black",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        sx={{
          mt: "2rem",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Home;
