import React from 'react'
import { Container, Box, Typography, Button, Divider } from '@mui/material'
import PriceListContainer from '../components/PriceListContainer/PriceListContainer'

const Product = () => {
  return (
    <Container maxWidth='xl'>
        <Box mt={2}>
          <PriceListContainer/>          
        </Box>
    </Container>
  )
}

export default Product