import { Box, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { ThemeContext } from '../../context/ThemeContext'

const ProgressBar = ({ progress }) => {
  const {colors}=useContext(ThemeContext)
  return (
    <Box sx={{
      width: '100%',
      backgroundColor: "#E7E7E7",
      height: "20px",
      position: "relative",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    }}>
      <Typography sx={{ color: "black", position: "relative", zIndex: 999, fontSize: "12px", fontWeight: "bold" }}>{progress}%</Typography>
      <Box sx={{
        position: "absolute",
        width: `${parseInt(progress)}%`,
        height: "20px",
        top: 0,
        left: 0,
        backgroundColor: `${parseInt(progress) < 100 ? colors.primary : 'success.main'}`,
      }}/>


    </Box>
  )
}

export default ProgressBar