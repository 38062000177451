import React from 'react'
import { Box } from '@mui/material'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
  };

const BoxModal = ({children}) => {
  return (
    <Box sx={style}>
        {children}
    </Box>
  )
}

export default BoxModal