import {
  Badge,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Typography,
  Tooltip,
  Chip,
  Box,
  TableCell,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import DnsIcon from "@mui/icons-material/Dns";
import LanguageIcon from "@mui/icons-material/Language";
import { ThemeContext } from "../../context/ThemeContext";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const ClientCard = ({ data, handleClickDelete, handleClickEdit }) => {
  const { colors } = useContext(ThemeContext);
  const [dominio, setDominio] = useState(0);
  const [hosting, setHosting] = useState(0);
  const badges = () => {
    data.services.map((service) => {
      const title =
        service?.product?.title?.toLowerCase().substring(0, 7) || "";
      title === "hosting" && setHosting(hosting + 1);
      title === "dominio" && setDominio(dominio + 1);
    });
  };

  useEffect(() => {
    badges();
  }, []);

  return (
    // <Box sx={{ p:2, backgroundColor:'#EEEEEE',borderRadius:2, position:'relative'}}>
    //     <Box sx={{ backgroundColor:'#EEEEEE',position:'absolute',top:0,right:0, borderRadius:10 }}>
    // <IconButton sx={{ color:'red', zIndex:1, }}  onClick={()=>handleClickDelete(data._id)}>
    //     <RemoveCircleIcon/>
    // </IconButton>
    //     </Box>
    //     <Box sx={{ backgroundColor:'#EEEEEE',position:'absolute',top:85,right:0, borderRadius:10 }}>
    // <IconButton sx={{ color:'purple', zIndex:1, }} size='small'  onClick={()=>handleClickEdit(data._id)}>
    //     <ModeEditIcon/>
    // </IconButton>
    //     </Box>
    //     <Card  sx={{ maxWidth:250 }}>

    //         <CardHeader title={data.company} subheader={`${data.firstName} ${data.lastName}`} sx={{backgroundColor:'black', color:'white', '.MuiCardHeader-subheader':{color:'white'}}}/>
    //         <CardContent component='div'>
    //             <Stack direction={'column'} spacing={2}>
    //                 <Typography variant="h6" color="initial" gutterBottom sx={{ backgroundColor:data.state==='new'?colors.primary:data.state==='active'?'green':'red', color:data.state==='new'?'black':'white', borderRadius:3 }}>{
    //                 data.state==='new'?'NUEVO':
    //                 data.state==='active'?'ACTIVO':'BAJA'
    //                 }</Typography>
    //                 <Tooltip title={data.phoneNumber}>
    //                     <Chip variant='outlined' icon={<LocalPhoneIcon/>} label={data.phoneNumber} sx={{justifyContent:'left', pl:1}}/>
    //                 </Tooltip>
    //                 <Tooltip title={data.email}>
    //                     <Chip variant='outlined' icon={<AlternateEmailIcon/>} label={data.email} sx={{justifyContent:'left', pl:1}}/>
    //                 </Tooltip>
    //                 <Tooltip title={data.url}>
    //                     <Chip variant='outlined' icon={<LanguageIcon/>} label={data.url} sx={{justifyContent:'left', pl:1}} component='a' href={data.url} target="_blank" clickable/>
    //                 </Tooltip>
    //                 <Stack direction='row' justifyContent={'center'}>

    //                     <Tooltip title="Hostings">
    //                         <IconButton>
    //                             <Badge badgeContent={hosting} color='secondary' anchorOrigin={{ vertical:'bottom', horizontal:'right' }}>
    //                                 <LanguageIcon/>
    //                             </Badge>
    //                         </IconButton>
    //                     </Tooltip>
    //                     <Tooltip title="Dominios">
    //                         <IconButton>
    //                             <Badge badgeContent={dominio} color='secondary' anchorOrigin={{ vertical:'bottom', horizontal:'right' }}>
    //                                 <DnsIcon/>
    //                             </Badge>
    //                         </IconButton>
    //                     </Tooltip>
    //                 </Stack>
    //             </Stack>
    //         </CardContent>

    //     </Card>
    // </Box>
    <>
      <TableCell>{data.company}</TableCell>
      <TableCell>{`${data.firstName} ${data.lastName}`}</TableCell>
      <TableCell>
        <Typography
          variant="h6"
          fontSize={"14px"}
          color="initial"
          gutterBottom
          sx={{
            backgroundColor:
              data.state === "new"
                ? colors.primary
                : data.state === "active"
                ? "green"
                : "red",
            color: data.state === "new" ? "black" : "white",
            borderRadius: 3,
            textAlign: "center",
          }}
        >
          {data.state === "new"
            ? "NUEVO"
            : data.state === "active"
            ? "ACTIVO"
            : "BAJA"}
        </Typography>
      </TableCell>
      <TableCell>{data.phoneNumber} </TableCell>
      <TableCell>{data.email} </TableCell>
      <TableCell>
        <Chip
          variant="outlined"
          icon={<LanguageIcon />}
          label={data.url}
          sx={{ justifyContent: "left", pl: 1 }}
          component="a"
          href={data.url}
          target="_blank"
          clickable
        />
      </TableCell>
      <TableCell>
        <Tooltip title="Hostings">
          <IconButton>
            <Badge
              badgeContent={hosting}
              color="secondary"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <LanguageIcon />
            </Badge>
          </IconButton>
        </Tooltip>
        <Tooltip title="Dominios">
          <IconButton>
            <Badge
              badgeContent={dominio}
              color="secondary"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <DnsIcon />
            </Badge>
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell>
        <IconButton
          sx={{ color: "purple", zIndex: 1 }}
          size="small"
          onClick={() => handleClickEdit(data._id)}
        >
          <ModeEditIcon />
        </IconButton>
        <IconButton
          sx={{ color: "red", zIndex: 1 }}
          onClick={() => handleClickDelete(data._id)}
        >
          <RemoveCircleIcon />
        </IconButton>
      </TableCell>
    </>
  );
};

export default ClientCard;
