import { Alert, Avatar, Box, Collapse, Fab, Grid, Paper, Stack, TextField, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import ButtonZaz from '../ButtonZaz/ButtonZaz';
import { ThemeContext } from '../../context/ThemeContext';
import {useFormik} from 'formik';
import * as yup from 'yup';
import { UserContext } from '../../context/UserContext';
import LogoBackground from '../LogoBackground/LogoBackground';
import CloseIcon from '@mui/icons-material/Close';
import { ConfigContext } from '../../context/ConfigContext';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
const validationSchema = yup.object({
    title: yup
        .string('Ingrese titulo')
        .required('Es necesario que ingrese su nombre'),
    description: yup
        .string('Ingrese descripcion'),
    priceNational: yup
        .number('Ingrese Precio Nacional'),
    priceInternational: yup
        .string('Ingrese Precio Internacional')
})

const ProductForm = ({data,actionClick, actionClose, editMode,  productData}) => {
    const {colors}=useContext(ThemeContext)
    const {token} = useContext(UserContext)
    const {cotizacion} = useContext(ConfigContext)
    const [open, setOpen]=useState(false);
    const [message, setMessage]=useState("");
    const axiosPrivate = useAxiosPrivate();

      

    const formik = useFormik({
        initialValues: productData,
        validationSchema: validationSchema,
        onSubmit: async (values)=>{
                if(!editMode){
                    values.priceNational=values.priceNational / cotizacion
                    const result = await axiosPrivate.post(`/products/`,JSON.stringify(values,null,2))
                    .catch((err)=>{
                    const errorMessage="Intente nuevamente"
                    setOpen(true)
                    setMessage(errorMessage)
                    })
                    if(result.data.success){
                       actionClose()
                    } 
                }else{
                    values.priceNational=values.priceNational / cotizacion
                    const result = await axiosPrivate.put(`/products/${values._id}`,JSON.stringify(values,null,2))
                    .catch((err)=>{
                        const errorMessage="Intente nuevamente"
                        setOpen(true)
                        setMessage(errorMessage)
                        })
                    if(result.data.message){
                        actionClose()
                    } 
                }
 
        }
    })
  return (
    <>
    <Box component='form' >
        <Fab size='small' sx={{ position:'absolute', top:0, right:0, m:2, p:2,backgroundColor: colors.primary, color: 'black', '&:hover':{backgroundColor:colors.secondary, color:colors.primary} }} onClick={()=>actionClose()}><CloseIcon/></Fab>
    <LogoBackground/>
    </Box>
        <Paper elevation={20} sx={{ justifyContent:'center', padding:2,   }}>
            <Grid align='center'>
                <Avatar sx={{bgcolor:colors.primary}}>
                    <Box sx={{  height:40 }} component='img' src='/assets/images/logo-sin-fondo.svg'></Box>
                </Avatar>
                <Typography align='center' variant='h5'py={2} fullWidth>{editMode?"EDITAR PRODUCTO": "NUEVO PRODUCTO"}</Typography>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                <Box component={'form'} onSubmit={formik.handleSubmit}>
                    <Stack spacing={2} sx={{ mt:3 }}>
                        <TextField
                            id="title"
                            name="title"
                            label="Titulo"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            error={formik.touched.title && Boolean(formik.errors.title)}
                            helperText={formik.touched.title && formik.errors.title}
                            autoComplete="on"
                            fullWidth
                            required
                            size="small"
                        />
                        <TextField
                            id="description"
                            name="description"
                            label="Descripción"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                            autoComplete="on"
                            fullWidth
                            size="small"
                        />
                        <TextField
                        id="usernapriceNationalme"
                        name="priceNational"
                        label="Precio Nacional (en pesos)"
                        type="number"
                        value={formik.values.priceNational}
                        onChange={formik.handleChange}
                        error={formik.touched.priceNational && Boolean(formik.errors.priceNational)}
                        helperText={formik.touched.priceNational && formik.errors.priceNational}
                        autoComplete="on"
                        fullWidth
                        required
                        size="small"
                        />
                        <TextField
                        id="priceInternational"
                        name="priceInternational"
                        label="Precio Internacional (en u$s)"
                        type="number"
                        value={formik.values.priceInternational}
                        onChange={formik.handleChange}
                        error={formik.touched.priceInternational && Boolean(formik.errors.priceInternational)}
                        helperText={formik.touched.role && formik.errors.priceInternational}
                        autoComplete="on"
                        fullWidth
                        required
                        size="small"
                        />
                        <ButtonZaz type="submit">{editMode?"EDITAR":"NUEVO"} PRODUCTO</ButtonZaz>
                        
                        <Collapse in={open}>
                            <Alert
                            severity="error" variant="outlined"
                            sx={{ mb: 2 }}
                            onClose={()=>{
                                setOpen(false)
                            }}
                            >
                            {message}
                            </Alert>
                        </Collapse>
                    </Stack>
                </Box>
                </Grid>

            </Grid>
        </Paper>
    </>
  )
}

export default ProductForm