import axios from "../api/axios"
import useAuth from "./useAuth"


const useRefreshToken = () => {
    const {setAuth} = useAuth();

    const refresh = async()=>{
        const response = await axios.post ('/users/refreshToken',{},{withCredentials:true});
        setAuth(prev =>{
            return {...prev,accessToken: response.data.token}
        })
        return response.data.token
    }
  return refresh;
}

export default useRefreshToken