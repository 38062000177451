import React from 'react'
import { Container, Box, Typography, Button, Divider } from '@mui/material'
import UserListContainer from '../components/UserListContainer/UserListContainer'

const Users = () => {
  return (
    <Container maxWidth='xl'>
        <Box mt={2}>
          <UserListContainer/>
        </Box>
    </Container>
  )
}

export default Users