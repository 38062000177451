import { Alert, Avatar, Box, Collapse, Fab, Grid, MenuItem, Paper, Stack, TextField, Typography, Autocomplete } from '@mui/material'
import React, { useContext, useState } from 'react'
import ButtonZaz from '../ButtonZaz/ButtonZaz';
import { ThemeContext } from '../../context/ThemeContext';
import axios from 'axios';
import {useFormik} from 'formik';
import * as yup from 'yup';
import { UserContext } from '../../context/UserContext';
import LogoBackground from '../LogoBackground/LogoBackground';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
const validationSchema = yup.object({
    title: yup
        .string('Ingrese título de servicio')
        .required('Es necesario que ingrese el titulo'),
    client: yup
        .string('Ingrese Cliente')
        .required('Es necesario que ingrese cliente'),
    initDate: yup
        .date('Ingrese fecha de inicio')
        .required('Es necesario que ingrese la fecha de inicio'),
    expirationDate: yup
        .string('Ingrese fecha de vencimiento')
        .required('Es necesario que ingrese fecha de vencimiento'),
    provider: yup
        .string('Ingrese el proveedor'),
    state: yup
        .string('Ingrese estado')
        .required('Es necesario que ingrese el estado'),
    product: yup
        .string('Ingrese producto relacionado')
    
},{timestamps:true})

const ServiceForm = ({data,actionClick, actionClose, editMode, serviceData, clients, products}) => {
    const {colors}=useContext(ThemeContext)
    const {token} = useContext(UserContext)
    const [open, setOpen]=useState(false);
    const axiosPrivate = useAxiosPrivate();
    const [message, setMessage]=useState("");
    const config={
        withCredentials: true,
        headers:{
          "Content-Type":"application/json",
          Authorization:`Bearer ${token}`
        }
      }

    const handleClick=async (e)=>{
        formik.submitForm()
        if(!editMode){
            const result = await axiosPrivate.post(`/services/`,JSON.stringify(formik.values,null,2))
            .catch((err)=>{
            const errorMessage="Intente nuevamente"
            setOpen(true)
            setMessage(errorMessage)
            })
            if(result.data.success){
               actionClose()
            } 
        }else{
            const result = await axiosPrivate.put(`/services/${formik.values._id}`,JSON.stringify(formik.values,null,2))
            .catch((err)=>{
                const errorMessage="Intente nuevamente"
                setOpen(true)
                setMessage(errorMessage)
                })
            if(result.data.message){
                actionClose()
            } 
        }
        
    }
      
    const formik = useFormik({
        initialValues: serviceData,
        validationSchema: validationSchema,
        onSubmit: async (values)=>{
                
 
        }
    })

    
  return (
    <>
    <Box component='form' >
        <Fab size='small' sx={{ position:'absolute', top:0, right:0, m:2, p:2,backgroundColor: colors.primary, color: 'black', '&:hover':{backgroundColor:colors.secondary, color:colors.primary} }} onClick={()=>actionClose()}><CloseIcon/></Fab>
    <LogoBackground/>
    </Box>
        <Paper elevation={20} sx={{ justifyContent:'center', padding:2,   }}>
            <Grid align='center'>
                <Avatar sx={{bgcolor:colors.primary}}>
                    <Box sx={{  height:40 }} component='img' src='/assets/images/logo-sin-fondo.svg'></Box>
                </Avatar>
                <Typography align='center' variant='h5'py={2} fullWidth>{editMode?"EDITAR SERVICIO": "NUEVO SERVICIO"}</Typography>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                <Box component={'form'} onSubmit={formik.handleSubmit}>
                    <Stack spacing={2} sx={{ mt:3 }}>
                        <TextField
                        id="title"
                        name="title"
                        label="Titulo"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        error={formik.touched.title && Boolean(formik.errors.title)}
                        helperText={formik.touched.title && formik.errors.title}
                        autoComplete="on"
                        fullWidth
                        required
                        size="small"
                        />
                          <Autocomplete
                            id="client"
                            name="client"
                            label="Cliente"
                            size="small"
                            defaultValue={formik.values.client}
                            options={clients}
                            getOptionLabel={option=>option.company}
                            onChange={(e,value)=>{
                                formik.values.client=value._id
                            }}
                            isOptionEqualToValue={(option, value)=>option._id===value._id}
                            renderInput = {params => (
                                <TextField
                                    label="Cliente"
                                    error={formik.touched.client && Boolean(formik.errors.client)}
                                    helperText={formik.touched.client && formik.errors.client}
                                    autoComplete="on"
                                    fullWidth
                                    
                                    required
                                    {...params}

                                />
                            )}
                        />
                        
                      <TextField
                            id="initDate"
                            name="initDate"
                            label="Fecha de inicio"
                            type="date"
                            value={dayjs(formik.values.initDate).format('YYYY-MM-DD')}
                            onChange={formik.handleChange}
                            error={formik.touched.initDate && Boolean(formik.errors.initDate)}
                            helperText={formik.touched.initDate && formik.errors.initDate}
                            autoComplete="on"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            size="small"
                        />
                        <TextField
                            id="expirationDate"
                            name="expirationDate"
                            label="Fecha de vencimiento"
                            type="date"
                            size="small"
                            value={dayjs(formik.values.expirationDate).format('YYYY-MM-DD')}
                            onChange={formik.handleChange}
                            error={formik.touched.expirationDate && Boolean(formik.errors.expirationDate)}
                            helperText={formik.touched.expirationDate && formik.errors.expirationDate}
                            autoComplete="on"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        /> 
                        <TextField
                         size="small"
                        id="provider"
                        name="provider"
                        label="Proveedor"
                        value={formik.values.provider}
                        onChange={formik.handleChange}
                        error={formik.touched.provider && Boolean(formik.errors.provider)}
                        helperText={formik.touched.provider && formik.errors.provider}
                        autoComplete="on"
                        fullWidth
                        />
                       
                        <TextField
                         size="small"
                        id="state"
                        name="state"
                        label="Estado"
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        error={formik.touched.state && Boolean(formik.errors.state)}
                        helperText={formik.touched.state && formik.errors.state}
                        autoComplete="on"
                        fullWidth
                        required
                        select
                        >
                            <MenuItem value='active'>ACTIVO</MenuItem>
                            <MenuItem value='expiring'>PROXIMO VENCIMIENTO</MenuItem>
                            <MenuItem value='expired'>VENCIDO</MenuItem>
                        </TextField>
                           

                     
                        <TextField
                         size="small"
                        id="product"
                        name="product"
                        label="Producto relacionado"
                        defaultValue={formik.values.product?._id?formik.values.product._id:'noProduct'}
                        onChange={formik.handleChange}
                        error={formik.touched.product && Boolean(formik.errors.product)}
                        helperText={formik.touched.product && formik.errors.product}
                        autoComplete="on"
                        fullWidth
                        select
                        >
                            <MenuItem value={'noProduct'}>Sin Producto Relacionado</MenuItem>
                            {

                                products.map((product)=>(
                                    <MenuItem key={product._id} value={product._id}>{product.title}</MenuItem>
                                ))
                            }
                        </TextField>
                     
                        <ButtonZaz onClick={handleClick}>{editMode?"EDITAR":"NUEVO"} SERVICIO</ButtonZaz>
                        
                        <Collapse in={open}>
                            <Alert
                            severity="error" variant="outlined"
                            sx={{ mb: 2 }}
                            onClose={()=>{
                                setOpen(false)
                            }}
                            >
                            {message}
                            </Alert>
                        </Collapse>
                    </Stack>
                </Box>
                </Grid>

            </Grid>
        </Paper>
    </>
  )
}

export default ServiceForm