import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'

const DashboardTable = ({data}) => {
	const [lista, setLista] = useState([])
	useEffect(() => {
		const sortedLista = data.sort((a, b) => dayjs(a.expirationDate).diff(dayjs(b.expirationDate), 'days'))
		setLista(sortedLista)

	},[data])


  return (
    <>
    {
        lista.length>0 &&
        <Paper>
            <TableContainer >
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"  sx={{ fontWeight:'bold',p:1, backgroundColor:'black', color:'white' }}>
                                Cliente
                            </TableCell>
                            <TableCell align='center'  sx={{ fontWeight:'bold',p:1, backgroundColor:'black', color:'white' }}>
                                Servicio
                            </TableCell>
                            <TableCell align='right' sx={{ fontWeight:'bold',p:1, backgroundColor:'black', color:'white' }}>
                                Vencimiento
                            </TableCell>
                            <TableCell align='center' sx={{ fontWeight:'bold',p:1, backgroundColor:'black', color:'white' }}>
                                Estado
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                                data.map((service)=>(
                                    <TableRow key={service._id}>
                                        <TableCell>{service.client.company}</TableCell>
                                        <TableCell align='right'>{service.product.title}</TableCell>
                                        <TableCell align='right'>{dayjs(service.expirationDate).format('DD/MM/YYYY') }</TableCell>
                                        <TableCell>{(service.state==='expiring')?'PROXIMO VENCIMIENTO': 'ACTIVO'}</TableCell>
                                    </TableRow>
                                )
                                )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    }
    </>
  )
}

export default DashboardTable