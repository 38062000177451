import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale,BarElement,Title, PointElement,LineElement   } from 'chart.js';
import { Doughnut, Bar, Line } from 'react-chartjs-2';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,LinearScale,PointElement,LineElement,BarElement,Title);


 

const labels = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul','Ago', 'Set', 'Oct', 'Nov', 'Dic'];
const datasets = [
    {
      label: 'Servicios',
      data: [1,2,5,8,1,0,0,0,0,1,0],
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
  ];
const datasetLine=[
    {
        label: 'Finanzas',
        data:[1500,-1000,25000,-5000,1110],
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
    }
]
const dataBar={
    labels,
    datasets:datasets
}
const dataLine={
    labels,
    datasets:datasetLine
}

const DashboardChart = ({type,data, dataset}) => {

  const options={
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position:'bottom'
        },
        title: {
            display: true,
            text: 'GRAFICO',
          },
    },
  }
  if(type==='doughnut'){
    data.datasets[0].data=dataset
    options.plugins.title.text="Servicios"
  }else if(type==='bar'){
    options.plugins.title.text="Servicios Mensuales"
  }else{
    options.plugins.title.text="Movimientos Mensuales"
  }
  return (
    <Card >
        
        <CardContent >
            {
                type==='doughnut'?
                <Doughnut data={data} options={options} />
                :
                type==='bar' ?
                    <Bar options={options} data={dataset}/>
                    :
                    <Line options={options} data={dataset}/>

            }
        </CardContent>
    </Card>
  )
}

export default DashboardChart