import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal } from '@mui/material'
import React, {  useEffect, useState } from 'react'
import UserList from '../UserList/UserList'
import Loading from '../Loading/Loading'
import BoxModal from '../BoxModal/BoxModal'
import UserForm from '../Form/UserForm'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'


const initialValue={
  username:'',
  firstName:'',
  lastName:'',
  role:'admin',
  password:'',
}

const UserListContainer = () => {
  const axiosPrivate = useAxiosPrivate()
  const [users, setUsers]=useState([])
  const [loading, setLoading]=useState(false)
  const [idUser, setIdUser]= useState();
  const [openDialog, setOpenDialog]=useState(false);
  const [open, setOpen]=useState(false);
  const [editMode, setEditMode] =useState(false);
  const [userData, setUserData]=useState(initialValue);

  const handleClickDelete=(id)=>{
    setIdUser(id)
    setOpenDialog(true)
  }
  const handleClickEdit= (id)=>{
    setLoading(true)
    setEditMode(true)
    setIdUser(id)
    const datos =  getUser(id)
  }
  const handleOpen=(state)=>{
    setEditMode(state)
    setOpen(true)};


  const handleClose=()=>{
    getUsers();
    setUserData(initialValue)
    setOpen(false)
  };

  const handleClick=()=>{
    editMode?
    (editUser())
    :
    (createUser())
    
  }
  const createUser=async()=>{

    console.log("NUEVO USUARIO",)
  }
  const editUser=async()=>{
    console.log('Edita Usuario',userData)
  }
  const handleClickOpenDialog =()=>{
    setOpenDialog(true)
  }
  const handleCloseOpenDialog=()=>{
    setOpenDialog(false)
  }

  const handleDelete= async()=>{
    try {
      const result = await axiosPrivate.delete(`/users/${idUser}` )
      handleCloseOpenDialog()
      getUsers();
    } catch (error) {
      console.log("ERROR BORRADO", error)      
    }
  }

  const getUsers = async()=>{
    try {
      const result = await axiosPrivate.get(`/users`)
      if(result.data){
        setUsers(result.data.data)
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getUser = async(id)=>{
    try {
      const userResult = await axiosPrivate.get(`/users/${id}` )
      if(userResult){
       await setUserData(userResult.data.data)
       setLoading(false)
       setOpen(true)

      }

    } catch (error) {
      
    }
  }
  useEffect(()=>{
    setLoading(true)
    getUsers()
  },[])


  return (
    <>
      {loading ? 
      <Box sx={{ display:'flex', margin:'30% auto', justifyContent:'center', alignContent:'center', textAlign:'center' }}><Loading/></Box>
       :
     <UserList users={users} handleClickDelete={handleClickDelete} handleClickEdit={handleClickEdit} handleOpen={handleOpen}/>}

      <Dialog
        open={openDialog}
        onClose={handleCloseOpenDialog}
      >
        <DialogTitle>
          {`Desea eliminar al usuario?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Si lo elimina no podrá ingresar nuevamente</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOpenDialog} autoFocus>NO</Button>
          <Button onClick={handleDelete}>SI</Button>
        </DialogActions>

      </Dialog>

      <Modal
          open={open}
          onClose={handleClose}
      >
          <BoxModal>
            <UserForm actionClose={handleClose} userData={userData} editMode={editMode}/>
          </BoxModal>
      </Modal>
   
    </>
          
  
    )
}

export default UserListContainer