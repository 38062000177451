import { Box, Fab, Grid, Modal, Paper, Stack, Table, TableBody, TableCell, ButtonGroup, IconButton, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import InputIcon from '@mui/icons-material/Input';
import OutputSharpIcon from '@mui/icons-material/OutputSharp';
import { ThemeContext } from '../../context/ThemeContext';
import dayjs from 'dayjs';
import DashboardChart from '../Dashboard/DashboardChart/DashboardChart';
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import DashboardCard from '../Dashboard/DashboardCard/DashboardCard';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const chartData = {
    labels: [ 'Vencidos', 'Proximos','Activos'],
    datasets: [
      {
        label: '# Servicios',
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        borderWidth: 1,
        
      },
    ],
  };

const TransactionList = ({transactions, handleClickEdit,handleClickDelete, handleOpen}) => {
    const {colors}=useContext(ThemeContext)
    const [page, setPage]= useState(0);
    const [rowsPerPage, setRowsPerPage]=useState(10);
    const [open, setOpen]=useState(false);
    const [info, setInfo]=useState()
    const [loading, setLoading]=useState(false)
    const [balance, setBalance] = useState()
    const axiosPrivate=useAxiosPrivate();
    const handleChangePage =(e,newPage)=>{
      setPage(newPage);
  }
  const handleChangeRowsPerPage=(e)=>{
      setRowsPerPage(e.target.value);
      setPage(0);
  }


  const getInfo = async () => {
    const res = await axiosPrivate.get(`/admin/dashboard`)
    if(res.data.success){
      setInfo(res.data.data)
    }
    setLoading(false)
  }
  useEffect(() => {
    setLoading(true)
    getInfo()
    const debe = transactions.filter(transaction=>transaction.type==='out')
    const haber = transactions.filter(transaction=>transaction.type==='in')
    const ndebe = debe.reduce((a,b)=>a+b.amount,0)
    const nhaber = haber.reduce((a,b)=>a+b.amount,0)
    const total = ndebe + nhaber
    setBalance({
        haber: nhaber.toFixed(2),
        debe: ndebe.toFixed(2),
        total: total.toFixed(2)
    })
  },[transactions])

  return(
    <>
        {
            loading?
            <h1>Loading</h1>
            :
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box component='div' sx={{ mt:'2em', mb:2, display:'flex', alignItems:'center' }}>
                            <Typography variant='h5'>LISTADO DE TRANSACCIONES</Typography>
                            <Tooltip title='Nueva Transaccion'>
                            <Fab size='small' sx={{ mx:5, p:2, backgroundColor: colors.primary, color: colors.secondary, '&:hover':{backgroundColor:colors.secondary, color:colors.primary} }} color='primary' onClick={handleOpen}><AddIcon/></Fab>
                            </Tooltip>
                        </Box>
                    </Grid>
                    {
                        balance &&
                        <>
                            <DashboardCard data={{title:'ENTRADA', amount:`$ ${balance.haber}`}}/>
                            <DashboardCard data={{title:'SALIDA', amount:`$ ${balance.debe}`}}/>
                            <DashboardCard data={{title:'TOTAL', amount:`$ ${balance.total}`}}/>
                            <DashboardCard data={{title:'PARCIAL', amount:`$ ${(balance.total / 2).toFixed(2)}`}}/>
                        </>
                    }
                    <Grid item xs={12} md={8}>
                        <Paper elevation={10}>
                            <Stack>
                                <TableContainer component={Paper} >
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell  sx={{ backgroundColor:'black', color:'white',fontWeight:'bold', borderColor:'white',p:1 }} align='center'>Tipo</TableCell>
                                                <TableCell  sx={{ backgroundColor:'black', color:'white',fontWeight:'bold', borderColor:'white',p:1 }} align='center'>Fecha</TableCell>
                                                <TableCell  sx={{ backgroundColor:'black', color:'white',fontWeight:'bold', borderColor:'white',p:1 }} align='center'>Cantidad</TableCell>
                                                <TableCell  sx={{ backgroundColor:'black', color:'white',fontWeight:'bold', borderColor:'white',p:1 }}>Descripción</TableCell>
                                                <TableCell  sx={{ backgroundColor:'black', color:'white',fontWeight:'bold', borderColor:'white',p:1 }}>Acciones</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                transactions.map((transaction)=>{
                                                    return(
                                                        <TableRow
                                                            key={transaction._id}
                                                        >
                                                            <TableCell>
                                                                {transaction.type==='in'?
                                                                    (<InputIcon color='success'/>)
                                                                    :
                                                                    (<OutputSharpIcon color='error'/>)
                                                                }
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {dayjs(transaction.date).format('DD/MM/YYYY')}
                                                            </TableCell>
                                                            <TableCell>
                                                                $ {transaction.amount}
                                                            </TableCell>
                                                            <TableCell>
                                                                {transaction.description}
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                <ButtonGroup size='small'>
                                                                    <Tooltip title="Editar">
                                                                    <IconButton color='secondary' onClick={()=>handleClickEdit(transaction._id)} size='small'>
                                                                        <EditIcon/>
                                                                    </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Eliminar">
                                                                    <IconButton color='error' onClick={()=>handleClickDelete(transaction._id)} size='small'>
                                                                        <DeleteIcon/>
                                                                    </IconButton>
                                                                    </Tooltip>
                                                                </ButtonGroup>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10,25,100]}
                                    component ="div"
                                    count={transactions.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    labelRowsPerPage='Servicios por página'
                                    labelDisplayedRows={({from,to,count})=>{ return `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`}}
                                />
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <Grid container>
                            <Grid item sm={12}>
                                {
                                    info && <DashboardChart data={chartData} type={'line'} dataset={info?.dataLine}/>
                                }
                            </Grid>

                        </Grid>

                    </Grid>
                    
                    </Grid>
        }
    </>
  ) 

        

  
}

export default TransactionList