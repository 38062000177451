import {createContext,useState} from 'react'

export const UserContext = createContext();


const UserProvider =({children})=>{
    const[token, setToken]=useState()
    const [firstName, setFirstName]=useState();

    const setNewToken = (data) => {
        setToken(data)
    }
    const setNewFirstName = (data) => {
        setFirstName(data)
    }
    
    return (
        <UserContext.Provider value={{token, setNewToken, firstName, setNewFirstName}}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider