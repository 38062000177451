import { Alert, Avatar, Box, Collapse, Fab, Grid, MenuItem, Paper, Stack, TextField, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import ButtonZaz from '../ButtonZaz/ButtonZaz';
import { ThemeContext } from '../../context/ThemeContext';
import axios from 'axios';
import {useFormik} from 'formik';
import * as yup from 'yup';
import { UserContext } from '../../context/UserContext';
import LogoBackground from '../LogoBackground/LogoBackground';
import CloseIcon from '@mui/icons-material/Close';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import dayjs from 'dayjs';
const validationSchema = yup.object({
    type: yup
        .string('Ingrese Tipo')
        .required('Es necesario que ingresar tipo'),
    amount: yup
        .number('Ingrese Cantidad')
        .required('Es necesario que ingrese cantidad'),
    description: yup
        .string('Ingrese Descripcion')
        .required('Es necesario que ingrese descripcion'),
    date: yup
        .date('Ingrese Fecha')
        .required('Es necesario que ingrese la fecha'),
    sale: yup
        .string('Ingrese Presupuesto asignado')
},{timestamps:true})

const TransactionForm = ({data,actionClick, actionClose, editMode, transactionData, sales}) => {
    const {colors}=useContext(ThemeContext)
    const {token} = useContext(UserContext)
    const axiosPrivate = useAxiosPrivate();
    const [open, setOpen]=useState(false);
    const [message, setMessage]=useState("");
    
    const formik = useFormik({
        initialValues: transactionData,
        validationSchema: validationSchema,
        onSubmit: async (values)=>{
                if(!editMode){
                    if(values.type==='out'){
                        values.amount *= -1
                    }
                    const result = await axiosPrivate.post(`/transactions/`,JSON.stringify(values,null,2))
                    .catch((err)=>{
                    const errorMessage="Intente nuevamente"
                    setOpen(true)
                    setMessage(errorMessage)
                    })
                    if(result.data.success){
                       actionClose()
                    } 
                }else{
                    const result = await axiosPrivate.put(`/transactions/${values._id}`,JSON.stringify(values,null,2))
                    .catch((err)=>{
                        const errorMessage="Intente nuevamente"
                        setOpen(true)
                        setMessage(errorMessage)
                        })
                    if(result.data.message){
                        actionClose()
                    } 
                }
 
        }
    })
  return (
    <>
    <Box component='form' >
        <Fab size='small' sx={{ position:'absolute', top:0, right:0, m:2, p:2,backgroundColor: colors.primary, color: 'black', '&:hover':{backgroundColor:colors.secondary, color:colors.primary} }} onClick={()=>actionClose()}><CloseIcon/></Fab>
    <LogoBackground/>
    </Box>
        <Paper elevation={20} sx={{ justifyContent:'center', padding:2,   }}>
            <Grid align='center'>
                <Avatar sx={{bgcolor:colors.primary}}>
                    <Box sx={{  height:40 }} component='img' src='/assets/images/logo-sin-fondo.svg'></Box>
                </Avatar>
                <Typography align='center' variant='h5'py={2} fullWidth>{editMode?"EDITAR TRANSACCION": "NUEVO TRANSACCION"}</Typography>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                <Box component={'form'} onSubmit={formik.handleSubmit}>
                    <Stack spacing={2} sx={{ mt:3 }}>
                        <TextField
                        id="type"
                        name="type"
                        label="Tipo"
                        value={formik.values.type}
                        onChange={formik.handleChange}
                        error={formik.touched.type && Boolean(formik.errors.type)}
                        helperText={formik.touched.type && formik.errors.type}
                        fullWidth
                        required
                        select
                        size="small"
                        >
                            <MenuItem value="in">
                                Entrada
                            </MenuItem>
                            <MenuItem value="out">
                                Salida
                            </MenuItem>
                        </TextField>
                    
                        <TextField
                            id="amount"
                            name="amount"
                            label="Cantidad"
                            type="number"
                            value={formik.values.amount}
                            onChange={formik.handleChange}
                            error={formik.touched.amount && Boolean(formik.errors.amount)}
                            helperText={formik.touched.amount && formik.errors.amount}
                            autoComplete="on"
                            fullWidth
                            required
                            size="small"
                        />
                        <TextField
                            id="description"
                            name="description"
                            label="Descripcion"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                            autoComplete="on"
                            fullWidth
                            size="small"
                        />
                        <TextField
                        id="date"
                        name="date"
                        label="Fecha"
                        type="date"
                        value={dayjs(formik.values.date).format('YYYY-MM-DD')}
                        onChange={formik.handleChange}
                        error={formik.touched.date && Boolean(formik.errors.date)}
                        helperText={formik.touched.date && formik.errors.date}
                        autoComplete="on"
                        fullWidth
                        required
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        />
                        <TextField
                        id="sale"
                        name="sale"
                        label="N° de Presupuesto relacionado"
                        value={formik.values.sale}
                        onChange={formik.handleChange}
                        error={formik.touched.sale && Boolean(formik.errors.sale)}
                        helperText={formik.touched.sale && formik.errors.sale}
                        autoComplete="on"
                        fullWidth
                        select
                        size="small"
                        >
                            <MenuItem value={null}>Ninguno</MenuItem>
                            {
                                sales.map((sale)=>(
                                    <MenuItem key={sale._id} value={sale._id}>{sale.saleNumber}</MenuItem>
                                )
                                )
                            }
                        </TextField>
                        <ButtonZaz type="submit">{editMode?"EDITAR":"NUEVA"} TRANSACCION</ButtonZaz>
                        
                        <Collapse in={open}>
                            <Alert
                            severity="error" variant="outlined"
                            sx={{ mb: 2 }}
                            onClose={()=>{
                                setOpen(false)
                            }}
                            >
                            {message}
                            </Alert>
                        </Collapse>
                    </Stack>
                </Box>
                </Grid>

            </Grid>
        </Paper>
    </>
  )
}

export default TransactionForm