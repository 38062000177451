import {
  Alert,
  Avatar,
  Box,
  Collapse,
  Fab,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonZaz from "../ButtonZaz/ButtonZaz";
import { ThemeContext } from "../../context/ThemeContext";
import axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormatLineSpacing } from "@mui/icons-material";
import { UserContext } from "../../context/UserContext";
import LogoBackground from "../LogoBackground/LogoBackground";
import CloseIcon from "@mui/icons-material/Close";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const endpoint = "https://api.gestion.zaz.com.ar/";

const customConfig = {
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};

const UserForm = ({
  data,
  actionClick,
  actionClose,
  editMode,
  initialValues,
  userData,
}) => {
  const { colors } = useContext(ThemeContext);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const validationSchema = !editMode
    ? yup.object({
        firstName: yup
          .string("Ingrese nombre")
          .required("Es necesario que ingrese su nombre"),
        lastName: yup
          .string("Ingrese apellido")
          .required("Es necesario que ingrese su apellido"),
        username: yup
          .string("Ingrese usuario")
          .required("Es necesario que ingrese su usuario"),
        role: yup
          .string("Ingrese Rol")
          .required("Es necesario que ingrese su rol"),
        password: yup
          .string("Ingrese su contraseña")
          .min(6, "Password debe tener 6 caracteres como mínimo")
          .required("Es necesario que ingrese su password"),
      })
    : yup.object({
        firstName: yup
          .string("Ingrese nombre")
          .required("Es necesario que ingrese su nombre"),
        lastName: yup
          .string("Ingrese apellido")
          .required("Es necesario que ingrese su apellido"),
        username: yup
          .string("Ingrese usuario")
          .required("Es necesario que ingrese su usuario"),
        role: yup
          .string("Ingrese Rol")
          .required("Es necesario que ingrese su rol"),
      });

  const formik = useFormik({
    initialValues: userData,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!editMode) {
        const result = await axiosPrivate
          .post(`/users/signup`, JSON.stringify(values, null, 2))
          .catch((err) => {
            const errorMessage = "Intente nuevamente";
            setOpen(true);
            setMessage(errorMessage);
          });
        if (result.data.success) {
          actionClose();
        }
      } else {
        const result = await axiosPrivate
          .put(`/users/${values._id}`, JSON.stringify(values, null, 2))
          .catch((err) => {
            const errorMessage = "Intente nuevamente";
            setOpen(true);
            setMessage(errorMessage);
          });
        if (result.data.message) {
          actionClose();
        }
      }
    },
  });

  return (
    <>
      <Box component="form">
        <Fab
          size="small"
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            m: 2,
            p: 2,
            backgroundColor: colors.primary,
            color: "black",
            "&:hover": {
              backgroundColor: colors.secondary,
              color: colors.primary,
            },
          }}
          onClick={() => actionClose()}
        >
          <CloseIcon />
        </Fab>
        <LogoBackground />
      </Box>
      <Paper elevation={20} sx={{ justifyContent: "center", padding: 2 }}>
        <Grid align="center">
          <Avatar sx={{ bgcolor: colors.primary }}>
            <Box
              sx={{ height: 40 }}
              component="img"
              src="/assets/images/logo-sin-fondo.svg"
            ></Box>
          </Avatar>
          <Typography align="center" variant="h5" py={2} fullWidth>
            {editMode ? "EDITAR USUARIO" : "NUEVO USUARIO"}
          </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Box component={"form"} onSubmit={formik.handleSubmit}>
              <Stack spacing={2} sx={{ mt: 3 }}>
                <TextField
                  id="firstName"
                  name="firstName"
                  label="Nombre"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  autoComplete="on"
                  fullWidth
                  required
                  size="small"
                />
                <TextField
                  id="lastName"
                  name="lastName"
                  label="Apellido"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  autoComplete="on"
                  fullWidth
                  required
                  size="small"
                />
                <TextField
                  id="username"
                  name="username"
                  label="Nombre de Usuario"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.username && Boolean(formik.errors.username)
                  }
                  helperText={formik.touched.username && formik.errors.username}
                  autoComplete="on"
                  fullWidth
                  required
                  size="small"
                />
                <TextField
                  id="role"
                  name="role"
                  label="Perfil de usuario"
                  value={formik.values.role}
                  onChange={formik.handleChange}
                  error={formik.touched.role && Boolean(formik.errors.role)}
                  helperText={formik.touched.role && formik.errors.role}
                  autoComplete="on"
                  fullWidth
                  required
                  size="small"
                />
                {!editMode && (
                  <>
                    <TextField
                      id="password"
                      name="password"
                      label="Contraseña"
                      type="password"
                      autoComplete="on"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      fullWidth
                      required
                      size="small"
                    />
                    <TextField
                      id="confirmPassword"
                      name="confirmPassword"
                      label="Repita Contraseña"
                      type="password"
                      autoComplete="on"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.confirmPassword &&
                        Boolean(formik.errors.confirmPassword)
                      }
                      helperText={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                      }
                      fullWidth
                      required
                      size="small"
                    />
                  </>
                )}
                <ButtonZaz type="submit">
                  {editMode ? "EDITAR" : "NUEVO"} USUARIO
                </ButtonZaz>

                <Collapse in={open}>
                  <Alert
                    severity="error"
                    variant="outlined"
                    sx={{ mb: 2 }}
                    onClose={() => {
                      setOpen(false);
                    }}
                  >
                    {message}
                  </Alert>
                </Collapse>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default UserForm;
