import { Alert, Autocomplete, Avatar, Box, Collapse, Fab, Grid, MenuItem, Paper, Stack, TextField, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import ButtonZaz from '../ButtonZaz/ButtonZaz';
import { ThemeContext } from '../../context/ThemeContext';
import axios from 'axios';
import {useFormik} from 'formik';
import * as yup from 'yup';
import { UserContext } from '../../context/UserContext';
import LogoBackground from '../LogoBackground/LogoBackground';
import CloseIcon from '@mui/icons-material/Close';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import dayjs from 'dayjs';
const validationSchema = yup.object({
    title: yup
        .string('Ingrese Titulo')
        .required('Es necesario que ingrese cantidad'),
    description: yup
        .string('Ingrese Descripcion'),
    creationDate: yup
        .date('Ingrese Fecha de Inicio')
        .required('Es necesario que ingrese la fecha'),
    expirationDate: yup
        .date('Ingrese Fecha de Vencimiento')
        .required('Es necesario que ingrese la fecha'),
    state: yup
        .string('Ingrese Estado')
        .required('Es necesario que ingrese estado'),
},{timestamps:true})

const TaskForm = ({data,actionClick, actionClose, editMode, taskData, clients}) => {
  const {colors}=useContext(ThemeContext)
    const {token} = useContext(UserContext)
    const axiosPrivate = useAxiosPrivate();
    const [open, setOpen]=useState(false);
    const [message, setMessage]=useState("");
    
    const formik = useFormik({
        initialValues: taskData,
        validationSchema: validationSchema,
        onSubmit: async (values)=>{
                if(!editMode){
                    const result = await axiosPrivate.post(`/tasks/`,JSON.stringify(values,null,2))
                    .catch((err)=>{
                    const errorMessage="Intente nuevamente"
                    setOpen(true)
                    setMessage(errorMessage)
                    })
                    if(result.data.success){
                       actionClose()
                    } 
                }else{
                    const result = await axiosPrivate.put(`/tasks/${values._id}`,JSON.stringify(values,null,2))
                    .catch((err)=>{
                        const errorMessage="Intente nuevamente"
                        setOpen(true)
                        setMessage(errorMessage)
                        })
                    if(result.data.message){
                        actionClose()
                    } 
                }
 
        }
    })
  return (
    <>
    <Box component='form' >
        <Fab size='small' sx={{ position:'absolute', top:0, right:0, m:2, p:2,backgroundColor: colors.primary, color: 'black', '&:hover':{backgroundColor:colors.secondary, color:colors.primary} }} onClick={()=>actionClose()}><CloseIcon/></Fab>
    <LogoBackground/>
    </Box>
        <Paper elevation={20} sx={{ justifyContent:'center', padding:2,   }}>
            <Grid align='center'>
                <Avatar sx={{bgcolor:colors.primary}}>
                    <Box sx={{  height:40 }} component='img' src='/assets/images/logo-sin-fondo.svg'></Box>
                </Avatar>
                <Typography align='center' variant='h5'py={2} >{editMode?"EDITAR TAREA": "NUEVA TAREA"}</Typography>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                <Box component={'form'} onSubmit={formik.handleSubmit}>
                    <Stack spacing={2} sx={{ mt:3 }}>
                      <Autocomplete
                            id="client"
                            name="client"
                            label="Cliente"
                            size="small"
                            freeSolo
                            options={clients}
                            defaultValue={formik.values.client}
                            getOptionLabel={option=>option?.company}
                            onChange={(e,value)=>{
                                formik.values.client=value._id
                            }}
                            renderInput = {params => (
                                <TextField
                                    label="Cliente"
                                    error={formik.touched.client && Boolean(formik.errors.client)}
                                    helperText={formik.touched.client && formik.errors.client}
                                    autoComplete="on"
                                    fullWidth
                                    
                                    required
                                    {...params}

                                />
                            )}
                        />
                        <TextField
                            id="title"
                            name="title"
                            label="Titulo"
                            type="text"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            error={formik.touched.title && Boolean(formik.errors.title)}
                            helperText={formik.touched.title && formik.errors.title}
                            autoComplete="on"
                            fullWidth
                            required
                            size="small"
                        />
                        <TextField
                            id="description"
                            name="description"
                            label="Descripcion"
                            multiline
                            minRows={3}
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                            autoComplete="on"
                            fullWidth
                            size="small"
                        />
                        <TextField
                        id="creationDate"
                        name="creationDate"
                        label="Fecha de Inicio"
                        type="date"
                        value={dayjs(formik.values.creationDate).format('YYYY-MM-DD')}
                        onChange={formik.handleChange}
                        error={formik.touched.creationDate && Boolean(formik.errors.creationDate)}
                        helperText={formik.touched.creationDate && formik.errors.creationDate}
                        autoComplete="on"
                        fullWidth
                        required
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        />
                        <TextField
                        id="expirationDate"
                        name="expirationDate"
                        label="Fecha de Vencimiento"
                        type="date"
                        value={dayjs(formik.values.expirationDate).format('YYYY-MM-DD')}
                        onChange={formik.handleChange}
                        error={formik.touched.expirationDate && Boolean(formik.errors.expirationDate)}
                        helperText={formik.touched.expirationDate && formik.errors.expirationDate}
                        autoComplete="on"
                        fullWidth
                        required
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        />
                        <Autocomplete 
                          id="progress"
                          name="progress"
                          label="Progreso"
                          options={['0','10','20','30','40','50','60','70','80','90','100']}
                          defaultValue={formik.values.progress}
                          onChange={(e,value)=>{
                            formik.values.progress=value
                          }}
                          renderInput={params => (
                            <TextField {...params} 
                              label="Progreso" 
                              size="small" 
                              error={formik.touched.progress && Boolean(formik.errors.progress)}
                              helperText={formik.touched.progress && formik.errors.progress}
                            />
                          )}
                        />
                        <Autocomplete 
                          id="state"
                          name="state"
                          label="Estado"
                          options={["Nuevo", "Trabajando", "Finalizado"]}
                          defaultValue={formik.values.state}
                          onChange={(e,value)=>{
                            formik.values.state=value
                          }}
                          renderInput = {params => (
                              <TextField
                                  label="Estado"
                                  error={formik.touched.state && Boolean(formik.errors.state)}
                                  helperText={formik.touched.state && formik.errors.state}
                                  autoComplete="on"
                                  fullWidth
                                  required
                                  {...params}

                              />
                            )}
                        />

                        <ButtonZaz type="submit">{editMode?"EDITAR":"NUEVA"} TAREA</ButtonZaz>
                        
                        <Collapse in={open}>
                            <Alert
                            severity="error" variant="outlined"
                            sx={{ mb: 2 }}
                            onClose={()=>{
                                setOpen(false)
                            }}
                            >
                            {message}
                            </Alert>
                        </Collapse>
                    </Stack>
                </Box>
                </Grid>

            </Grid>
        </Paper>
    </>
  )
}

export default TaskForm