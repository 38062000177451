import React, { useContext, useState } from 'react'
import { Grid, Box, Typography, Tooltip, Fab, Modal, Card, CardHeader, CardContent, CardActions, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import BoxModal from '../BoxModal/BoxModal';
import Form from '../Form/FormOld';
import { ThemeContext } from '../../context/ThemeContext';
import Loading from '../Loading/Loading';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { UserContext } from '../../context/UserContext';

const UserList = ({users, handleClickEdit,handleClickDelete, handleOpen} ) => {
  const {colors}=useContext(ThemeContext)
  
  return (
    <Grid container>
        <Grid item md={12}>
            <Box component='div' sx={{ mt:'2em', mb:2, display:'flex', alignItems:'center' }}>
                <Typography variant='h5'>LISTADO DE USUARIOS</Typography>
                <Tooltip title='Nuevo Servicio'>
                <Fab size='small' sx={{ mx:5, p:2, backgroundColor: colors.primary, color: colors.secondary, '&:hover':{backgroundColor:colors.secondary, color: colors.primary} }} color='primary' onClick={()=>handleOpen(false)}><AddIcon/></Fab>
                </Tooltip>
            </Box>
        </Grid>
        { 
          users.length > 0 ?
          users.map((user)=>{
            return(
                <Grid item key={user._id} >
                  <Card sx={{ m:1 }} >
                    <CardHeader title={`${user.firstName} ${user.lastName}`} titleTypographyProps={{ variant:'body1' }} sx={{py:1, px:0.5, backgroundColor:'black', color:'white'}}/>
                    <CardContent sx={{ py:1 }}>
                      <Typography variant='body2'>Usuario: {user.username}</Typography>
                      <Typography variant='body2'>Rol: {user.role}</Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent:'center', p:0 }}>
                      <IconButton color='secondary' onClick={()=>handleClickEdit(user._id)}>
                        <EditIcon/>
                      </IconButton>
                      <IconButton color='error' onClick={()=>handleClickDelete(user._id)}>
                        <DeleteIcon/>
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
            )
          })
          :
          <Loading/>
        }
        
     
    </Grid>
  )
}

export default UserList