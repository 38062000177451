import React from 'react'
import {Box, Button, Container, Divider, Typography} from '@mui/material'
import TransactionListContainer from '../components/TransactionListContainer/TransactionListContainer'

const Balance = () => {
  return (
    <Container maxWidth='xl'>
        <Box mt={2}>
          <TransactionListContainer/>
        </Box>
    </Container>
  )
}

export default Balance