import { Grid, Box, Stack, Tooltip, Typography, Fab, Table, TableContainer, Paper, TableCell, TableRow, TableHead, TableBody, Chip, ButtonGroup, IconButton, TablePagination, ToggleButtonGroup, ToggleButton, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import { ThemeContext } from '../../context/ThemeContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

const columns=[
    {id: 'id', label: 'N°'},
    {id: 'client', label: 'Cliente',},
    {id: 'products', label: 'Productos', align:'center'},
    {id: 'creationDate', label: 'Fecha',align:'center'},
    {id: 'price', label: 'Precio',  align:'right'},
    {id: 'state', label: 'Estado',  align:'center'},
    {id: 'actions', label: 'Acciones',  align:'center'},
]
const SalesList = ({sales, filterState,handleChangeFilterState, handleChangeSearch, handleOpen, handleClickEdit, handleClickDelete, handleClickSend,handleClickPrint}) => {
    const {colors}=useContext(ThemeContext)
    const [page, setPage]= useState(0);
    const [rowsPerPage, setRowsPerPage]=useState(10);
    const handleChangePage =(e,newPage)=>{
      setPage(newPage);
    } 
    const handleChangeRowsPerPage=(e)=>{
      setRowsPerPage(e.target.value);
      setPage(0);
    }
  
    return (
    <Grid container>
        <Grid item xs={12}>
            <Box component='div' sx={{ mt:'2em', mb:2, display:'flex', alignItems:'center' }}>
                <Typography variant='h5'>LISTADO DE PRESUPUESTOS
                </Typography>
                <Tooltip title='Nuevo Presupuesto'>
                <Fab size='small' sx={{ mx:5, p:2,backgroundColor: colors.primary, color: colors.secondary, '&:hover':{backgroundColor:colors.secondary, color:colors.primary} }} color='primary' onClick={handleOpen}><AddIcon/></Fab>
                </Tooltip>
            </Box>
        </Grid>
        <Grid item xs={12}>
            <TextField
                placeholder='Buscar Cliente'
                size='small'
                onChange={handleChangeSearch}
                fullWidth
            />
        </Grid>
        <Grid item xs={12}>
            <Stack direction={'row'} spacing={2} sx={{ mt:2 }}>
                <Typography variant='subtitle1' alignItems={'center'}>Filtrar:</Typography>
                <ToggleButtonGroup size='small' 
                    value={filterState}
                    onChange={handleChangeFilterState}  
                    aria-label="state filtering"
                    >
                    <ToggleButton value='ok' size='small' color='success'>
                        APROBADO
                    </ToggleButton>
                    <ToggleButton value='pending' size='small' color='warning'>
                        PENDIENTE
                    </ToggleButton>
                    <ToggleButton value='rejected' size='small' color='error'>
                        CADUCADO
                    </ToggleButton>
                    <ToggleButton value='send' size='small' color='info'>
                        ENVIADO
                    </ToggleButton>
                </ToggleButtonGroup>
            </Stack>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{ width:'100%', mt:2 }} elevation={10}>
                <TableContainer >
                    <Table stickyHeader aria-label="Tabla de servicios">
                        <TableHead>
                            <TableRow>
                                {
                                    columns.map((column)=>(
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth:column.minWidth}}
                                            sx={{ backgroundColor:'black', color:'white',fontWeight:'bold', borderColor:'white',p:1 }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                sales?.map((sale)=>{
                                    
                                    return(<TableRow key={sale._id}>
                                        <TableCell>{sale.saleNumber}</TableCell>
                                        <TableCell>{sale.client?.company}</TableCell>
                                        <TableCell>{sale.products.map((product)=>(
                                            <Chip key={product._id} size='small' label={product.title} sx={{m:0.5, display:'flex'}}/>
                                        )
      )}
                                        </TableCell>
                                        <TableCell align='center'>{dayjs(sale.creationDate).format("DD/MM/YYYY")}</TableCell>
                                        <TableCell align='right'>$ {sale.totalPrice - sale.totalPrice * sale.discount /100} <Typography variant="caption">({sale.discount}%)</Typography></TableCell>
                                        <TableCell align='center'>{
                                            sale.state === 'ok' ? <Chip label='APROBADO' color='success'/>
                                            : sale.state==='pending' ? <Chip label='PENDIENTE' color='warning'/>
                                            : <Chip label='CADUCADO' color='error'/>

                                        }</TableCell>
                                        <TableCell>
                                            <ButtonGroup>
                                                <Tooltip title="Enviar">
                                                    <IconButton color='info' onClick={()=>handleClickSend(sale._id)}>
                                                        <SendIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Imprimir" onClick={()=>handleClickPrint(sale._id)}>
                                                    <IconButton >
                                                        <PrintIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Editar" onClick={()=>handleClickEdit(sale._id)}>
                                                    <IconButton color='secondary'>
                                                        <EditIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Eliminar" onClick={()=>handleClickDelete(sale._id)}>
                                                    <IconButton color='error'>
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10,25,100]}
                    component ="div"
                    count={sales.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage='Presupuestos por página'
                    labelDisplayedRows={({from,to,count})=>{ return `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`}}
                />
            </Paper>
        </Grid>
    </Grid>
  )
}

export default SalesList